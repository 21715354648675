@import '../global.scss';

.e_holdings_modal {
    .e_dialog {
        max-width: 60rem;
        max-height: 80vh;
    }

    .e_content {
        border: none;
        border-radius: 1.5rem;
        max-height: 550px;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .e_close {
        top: 16px;
        right: 16px
    }

    // .e_table_wrapper {
    //     max-width: 650px;
    //     overflow-x: auto;
    // }
    .e_table_body {
        max-height: 400px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 0.125rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary-color !important;
            border-radius: 0.25rem;
        }
    }

    .e_overflow_container {
        &::-webkit-scrollbar {
            width: 0.125rem;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: $primary-color !important;
            border-radius: 0.25rem;
        }
    }
}

@media (max-width: 575px) {
    .e_holdings_modal {
        .e_dialog {
            max-width: 100%;
        }
        .e_content {
            min-height: auto;
        }
        .e_table_wrapper {
            overflow: auto;
            &::-webkit-scrollbar {
                height: 0.125rem;
            }
        
            &::-webkit-scrollbar-thumb {
                background-color: $primary-color !important;
                border-radius: 0.25rem;
            }
            .e_table {
                width: 900px;
            }
        }
        
        .e_table_body {
            // max-height: 60%;
           height: 100%;
        }
    }
}