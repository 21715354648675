@import '../global.scss';

.e_add_bank_modal {
    .e_dialog {
        max-width: 40rem;
        height: 80vh;
    }
    .e_content {
        border: none;
        border-radius: 1.5rem;
        min-height: 550px;
        overflow: hidden;
    }
    .e_close {
        top: 24px;
        right: 24px
    }
    .e_verify_btn {
        width: 220px;
        font-size: 16px!important;
    }
    .e_submit_button {
        width: 70%;
        font-size: 16px!important;
    }
    .e_loading_gif {
        width: 210px;
    }
   
    .e_qr_wrapper {
        img {
            width: 260px;
        }
    }
}

@media (max-width: 575px) {
    .e_add_bank_modal {
        .e_dialog  {
            // height: 100vh;
        }
        .e_content  {
            height: max-content;
            min-height: auto;
            .e_scroll_container {
                overflow-y: auto;
                overflow-x: hidden;
            &::-webkit-scrollbar {
                width: 0.175rem;
            }
        
            &::-webkit-scrollbar-thumb {
                border-radius: 0.25rem;
                background-color: $primary-color;
            }
            }   
        }
    }
}