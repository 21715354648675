@import "../global.scss";

.e_signature_modal {
    .e_dialog {
        max-width: 39.75rem;
    }

    .e_content {
        border-radius: 1.5rem;
        // height: 95vh;
    }
}