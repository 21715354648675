@import '../global.scss';


.e_invest_modal {
    .e_invest_dialog {
        max-width: 40rem;
        height: 80vh;
    }

    .e_invest_content {
        border: none;
        border-radius: 1.5rem;
        max-height: 95vh;
        // overflow: auto;
        user-select: none;
        &::-webkit-scrollbar {
            width: 0;
        }
        .e_modal_body {
            overflow-y: auto;
            overflow-x: hidden;
            width: 100%;
            max-height: 48.5vh;
            padding-right: 10px;
            &::-webkit-scrollbar {
                width: 0.175rem;
            }
        
            &::-webkit-scrollbar-thumb {
                border-radius: 0.25rem;
                background-color: $primary-color;
            }
        }

        .e_swp_modal_body {
            min-height: 44.5vh!important;
        }

        .e_close {
            top: 19px;
            right: 16px
        }

        .e_rotate_left_90 {
            transform: rotate(-90deg);
            transition: transform 0.3s ease;
        }

        .e_rotate_right_90 {
            transform: rotate(90deg);
            transition: transform 0.3s ease;
        }

        .e_info {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 11px;
                width: 1px;
                height: 27px;
                flex-shrink: 0;
                border-radius: 8px;
                right: -16px;
                background: $bright-gray;
            }
        }

        .select_bank_radio {
            width: 17.625rem;
        }

        .e_mandate_drop_down {
            top: 4rem;
            left: 0;
            width: 17.625rem;
            box-shadow: 4px 4px 48px 0px rgba(7, 0, 124, 0.08);
            max-height: 182px;
            overflow: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .e_mail_icon {

            right: 38px;
            top: -10px;

            svg {
                height: 32px;
            }

        }

        .e_email {
            right: -6rem;
            bottom: 1.875rem;
            box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.06);
            width: 14.125rem;
        }

        // payment screen
        .e_back {
            transform: rotate(-180deg);
            left: 16px;
            top: 16px;
        }

        .e_pin {
            width: 15.875rem;
            margin: 0 auto;

            input {
                height: 3.5rem;
                border: none;
                outline: none;
            }

            label {
                div {
                    width: 3.5rem;
                    height: 3.5rem;
                }
            }
        }


        .e_payment_icon {
            @extend .e_mail_icon;
            right: 0.75rem;

        }

        .e_folio {
            box-shadow: 4px 4px 48px 0px rgba(7, 0, 124, 0.08);
            top: 1.625rem;
            max-height: 230px;
            overflow: auto;
            &::-webkit-scrollbar {
                width: 0.175rem;
            }
        
            &::-webkit-scrollbar-thumb {
                border-radius: 0.25rem;
                background-color: $primary-color;
            }
        }

        .e_invest_folio {
            width: max-content;

            @media (max-width: 576px) {
                width: 13.125rem;
            }
        }
        .e_otp_section {
            height: 90%;
            margin-top: 20px;
            overflow-x: hidden;
            padding-right: 10px;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 0.175rem;
            }
        
            &::-webkit-scrollbar-thumb {
                border-radius: 0.25rem;
                background-color: $primary-color;
            }
        }
    }
}

.mt_transaction_modal {
    .e_invest_dialog {
        height: 85vh;
        .e_invest_content {
            max-height: 100vh;
        }
    }
}

@media (max-width: 575px) {
    .e_invest_modal {
        .e_invest_dialog {
            max-width: 100% !important;
        }
        .e_invest_content  {
            max-height: 100% !important;
            .e_mandate_drop_down {
                width: 100%;
            }
        }
        .select_bank_radio  {
            width: 100% !important;
        }
    }
    .mt_transaction_modal {
        .e_invest_dialog {
            max-width: 100% !important;
            .e_invest_content {
                max-height: 100% !important;
                .e_mandate_drop_down {
                    width: 100%;
                }
            }
        }
    }
}