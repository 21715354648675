@import '../global.scss';

.e_sip_modal {
    .e_dialog {
        max-width: 30.625rem;
    }

    .e_content {
        border: none;
        border-radius: 2rem;
        padding-top: 2rem;

        .e_close {
            top: 24px;
            right: 24px;
        }

        .e_warning {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            width: 10px;
        }
    }
}