@import '../global.scss';

.e_forgot_pin_modal {
    .e_dialog {
        max-width: 40.75rem;
    }

    .e_content {
        border: none;
        padding: 32px 24px 20px 24px;
        user-select: none;

        .e_close {
            top: 1.5rem;
            right: 1.5rem;
        }

        .e_back {
            top: 1.5rem;
            left: 1.5rem;
            transform: rotate(180deg);
        }

        .e_pin {
            width: 15.875rem;
            margin: 0 auto;

            input {
                height: 3.5rem;
                border: none;
                outline: none;
                opacity: 0;
            }

            label {
                div {
                    width: 3.5rem;
                    height: 3.5rem;
                }
            }
        }

        .e_new_pin {
            width: 17.125rem;
            margin: 0 auto;

            input {
                height: 3.5rem;
                border: none;
                outline: none;
                color: white;
                width: 100%;
            }

            label {
                top: 1.875rem;
                left: 0px;

                div {
                    width: 3.5rem;
                    height: 3.5rem;
                }
            }
        }
    }
}