.e_card_wrapper {
    background-image: url('../../Assets/Images/dashboard/investment-card-bg.png');
    background-repeat: no-repeat;
    background-size: contain;

    .e_positive_indicator {
        width: 0;
        height: 0;
        border-left: 0.5rem solid transparent;
        /* 8px converted to rem */
        border-right: 0.5rem solid transparent;
        /* 8px converted to rem */
        border-bottom: 0.5rem solid #27CF5E;
        /* 8px converted to rem */
        margin: 0.625rem 0.3125rem;
        /* 10px and 5px converted to rem */
    }

    .e_negative_indicator {
        width: 0;
        height: 0;
        border-left: 0.5rem solid transparent;
        /* 8px converted to rem */
        border-right: 0.5rem solid transparent;
        /* 8px converted to rem */
        border-bottom: 0.5rem solid red;
        /* 8px converted to rem */
        margin: 0.625rem 0.3125rem;
        /* 10px and 5px converted to rem */
        transform: rotate(180deg);
    }
}

.e_portfolio_tooltip {
    width: max-content;
    padding: 2px 8px;
    box-shadow: 4px 4px 12px 0px rgba(175, 175, 175, 0.58);
    top: -26px;
    left: -30px;
}