.e_outline_btn {
    position: relative;
    outline: none;

    &:hover {
        background: #a7c5d77c;
    }

    span {
        z-index: 2;
    }
}

.e_outline_btn[data-loading=true] {
    pointer-events: none!important;
    opacity: 0.6!important;
}
.e_disabled {
    pointer-events: none;
    opacity: 0.5;
}

// inactive btn style
.e_inactive_btn {
    @extend .e_outline_btn;
    pointer-events: none;
    opacity: 0.8;
}

.loader_gif {
    width: 20px;
    height: 20px;
    margin-top: 3px;
    margin-left: 6px;
}