@import '../global.scss';

.e_active_btn {
    transition: ease width 0.4s;
    &:hover {
        background: $primary-color;
        color: $white
    }
}

.e_active_btn[data-loading=true] {
    pointer-events: none!important;
    opacity: 0.6!important;
}

.e_loader_ring {
    transform: translateY(-14%);
    &::after {
        content: "";
        display: block;
        // width: 25px;
        // height: 25px;
        // margin: 8px;
        width: 20px;
        height: 20px;
        margin-top: 4px;
        margin-left: 8px;
        border-radius: 50%;
        border: 3px solid #fff;
        border-color: #fff transparent #fff transparent;
        animation: ring 1.2s linear infinite;
    }
}
.loader_gif {
    width: 20px;
    height: 20px;
    margin-top: 3px;
    margin-left: 6px;
}

@keyframes ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

// inactive btn style
.e_inactive_btn {
    @extend .e_active_btn;
    pointer-events: none;
    opacity: 0.6;
}