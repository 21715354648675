@import '../global.scss';

.e_unlock_modal {
    .e_unlock_dialogue {
        max-width: 40rem;

        .e_unlock_content {
            border-radius: 2rem;
            background: #FFF;
            padding: 2.188rem 2.875rem 1.5rem 2.875rem;
            user-select: none;

            .e_pin {
                bottom: -1.375rem;
                left: 0;
            }
        }

        .e_payment_content {
            border-radius: 2rem;
            background: #FFF;
            padding: 3.288rem 2.185rem 2.5rem 2.185rem;
            user-select: none;

            .e_pin {
                bottom: -1.375rem;
                left: 0;
            }
        }

        .e_modal_body {
            max-height: 80vh;
            overflow-y: scroll;
            overflow-x: hidden;
            padding-right: 10px;

            &::-webkit-scrollbar {
                width: 0.175rem;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 0.25rem;
                background-color: $primary-color;
            }
        }
    }
}

@media (max-width: 575px) {
    .e_unlock_modal {
        .e_unlock_dialogue {
            .e_payment_content {
                max-height: 80vh;
            }
            
            // .e_modal_body {
            //     max-height: 0vh !important;
            // }
        }
    }
}