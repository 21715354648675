@import '../global.scss';


.e_cart_modal {
    .e_cart_dialog {
        max-width: 40rem;
        height: 80vh;
    }

    .e_cart_content {
        border: none;
        border-radius: 1.5rem;
        max-height: 90vh;
        overflow: hidden;
        user-select: none;

        .e_modal_body {
            overflow-y: auto;
            overflow-x: hidden;
            width: 100%;
            padding-right: 10px;
            &::-webkit-scrollbar {
                width: 0.125rem;
            }
        
            &::-webkit-scrollbar-thumb {
                background-color: $primary-color !important;
                border-radius: 0.25rem;
            }
        }

        .e_close {
            top: 16px;
            right: 16px
        }

        .e_rotate_left_90 {
            transform: rotate(-90deg);
            transition: transform 0.3s ease;
        }

        .e_rotate_right_90 {
            transform: rotate(90deg);
            transition: transform 0.3s ease;
        }

        .select_bank_radio {
            width: 100%;
        }

        .e_mail_icon {

            right: 38px;
            top: -10px;

            svg {
                height: 32px;
            }

        }

        .e_email {
            right: -6rem;
            bottom: 1.875rem;
            box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.06);
            // width: 14.125rem;
        }

        // payment screen
        .e_back {
            transform: rotate(-180deg);
            left: 16px;
            top: 16px;
        }

        .e_pin {
            width: 15.875rem;
            margin: 0 auto;

            input {
                height: 3.5rem;
                border: none;
                outline: none;
            }

            label {
                div {
                    width: 3.5rem;
                    height: 3.5rem;
                }
            }
        }


        .e_payment_icon {
            @extend .e_mail_icon;
            right: 0.75rem;

        }

        .e_folio {
            box-shadow: 4px 4px 48px 0px rgba(7, 0, 124, 0.08);
            top: 1.625rem;
        }
    }
}

@media (max-width: 575px) {
    .e_cart_modal {
        .e_cart_content {
            max-height: 100%;
        }   
    }
}

@media (max-width: 400px) {
    .e_cart_modal {
        .e_cart_content {
            .e_mail_icon  {
                top: 7px!important;
                right: 55%!important;
            }
        }   
    }
}