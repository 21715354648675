@import "../global.scss";

.e_import_fund {
    .e_import_dialogue {
        max-width: 35rem;

        .e_import_content {
            border-radius: 2rem;
            border: none;
            padding: 1.5rem 3.25rem 2rem 3.25rem;

            .e_import_close {
                top: 1rem;
                right: 1rem
            }

            .e_pin {
                width: 15.875rem;
                margin: 0 auto;

                input {
                    height: 3.5rem;
                    border: none;
                    outline: none;
                }

                label {
                    div {
                        width: 3.5rem;
                        height: 3.5rem;
                    }
                }
            }
        }
    }
}