@import "../global.scss";

.e_invest_modal {
    .e_invest_dialog {
        max-width: 30rem;
        /* 480px converted to rem */

        .e_invest_content {
            .e_modal_body {
                overflow: auto;
                height: 398px;
                width: 100%;
            }

            .e_close {
                right: 1rem;
                /* 16px converted to rem */
                top: 1rem;
                /* 16px converted to rem */
            }

            .e_buttons {
                right: 24px;
                bottom: 24px;

                >button:first-of-type {
                    border-radius: 6px;
                }
            }
        }
    }
}