@import '../global.scss';

.e_external_fund_status_modal {
    .e_dialog {
        max-width: 40.75rem;
    }

    .e_content {
        border: none;

        .e_close {
            top: 2rem;
            right: 2rem;
        }
    }
}