@import "../global.scss";

.e_mandates {
    .e_dialog {
        max-width: 30.313rem;
    }

    .e_content {
        border: none;
        box-shadow: 4px 4px 48px 0px rgba(7, 0, 124, 0.08);

        .e_horizontal_line {
            width: 96%;
            height: 1px;
        }
    }
    .e_body {
        max-height: 70vh;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            width: 0.125rem;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: $primary-color !important;
            border-radius: 0.25rem;
        }
    }
}