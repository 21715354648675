@import "../global.scss";

.e_invite_modal {
    .e_dialog {
        max-width: 40.75rem;
    }

    .e_content {
        border-radius: 2rem;
        // height: 95vh;
    }
}