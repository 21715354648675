@import '../global.scss';

.e_news {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        right: -0.1875rem; /* 12px converted to rem */
        top: 0.5rem; /* 8px converted to rem */
        width: 0.1875rem; /* 3px converted to rem */
        height: 0.1875rem; /* 3px converted to rem */
        border-radius: 100%;
        opacity: 0.8;
        background: $gray;
    }
}

.e_news_image {
    border-top-left-radius: 1rem; /* 16px converted to rem */
    // border-top-right-radius: 1rem; /* 16px converted to rem */
    border-bottom-left-radius: 1rem;
    // height: 120px;
    position: relative;
    // top: -10px;
    // object-fit: cover;
}

.e_card_content {
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 98%;
    -webkit-line-clamp: 1;
}
