
@import "../global.scss";

.e_appointment_modal {
    .e_dialog {
       max-width: 26.125rem; 
    }
    .e_content {
        border-radius: 1rem;
        // height: 95vh;
    }
    .react-calender {
        border: none!important;
    }
}

@media (max-width: 1200px) {
    .e_appointment_modal {
        .e_dialog {
            height: 100% !important; 
        }
        .e_content {
            height: 90% !important;
        }
        .e_content_body {
            overflow: auto;
            height: 100% !important;
            &::-webkit-scrollbar {
                width: 0.175rem;
            }
        
            &::-webkit-scrollbar-thumb {
                border-radius: 0.25rem;
                background-color: $primary-color;
            }
        }
    }
}