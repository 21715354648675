@import "../global.scss";

.e_upload_signature_modal {
    .e_dialog {
        max-width: 35rem;
    }

    .e_content {
        border-radius: 2rem;
        // height: 95vh;
    }

    .e_close {
        top: 24px;
    }
}