/*
 *   File : color.scss
 *   Author : https://evoqins.com
 *   Description : SCSS file for define and export colors
 *   Integrations : NA
 *   Version : 1.0.0
*/

// Color declaration
$primary-color: #1177bd;
$granite-gray: #666666;
$black: #222b38;
$old-silver: #828282;
$white: #fff;
$platinum: #e5e5e5;
$ghost-white: #f8fafc;
$floral-white: #fffbee;
$bright-gray: #ecedef;
$anti-flash-white: #f2f2f2;
$cornsilk: #fff9e1;
$sea-green: #209e49;
$alice-blue: #f3f8fb;
$chinese-silver: #cccccc;
$dark-liver: #4d4d4d;
$pearl-aqua: #84dcc8;
$jett-black: #000000;
$ufo-green: #27cf5e;
$magnolia: #f5f5ff;
$davys-grey: #555555;
$charcoal: #3f455d;
$cadet-grey: #9299b5;
$lotion: #fffcfc;
$lavender-web: #e6e6f5;
$outer-space: #44475b;
$sapphire: #084ec7;
$true-blue: #0572e3;
$gray: #7c7e8c;
$gray-light: #808080;
$eerie-black: #1a1a1a;
$azureish-white: #d5e2f6;
$rich-black: #040505;
$ateneo-blue: #104061;
$new-car: #164dc5;
$red: #ff0000;
$light-silver: #d0d5dd;
$mint-cream: #f7fffd;
$chinese-white: #e0e0e0;
$orange-soda: #eb5b3c;
$pastel-green: #c9f4d7;
$dark-silver: #676d75;
$may-green: #5a9847;
$honey-dew: #ecfaf0;
$pale-steel-blue: #f1f9fe;
$nyanza: #ddf6e4;
$cream_green: #ddf6e4;
$greenish-yellow: #f6fff2;
$pale-blue: #b5c6f2;
$light-blue: #ebf3ff;
$deep-blue: #2a64d9;
$blue-sapphire: #2a63d6;
$light-gray: #d1d1d6;
$light-black: #222b38ad;
$yellow-green: #bce893;
$charleston-green: #252c32;
$gainsboro: #dfdfdf;
$minty: #d7f7e1;
$cream: #fefbe8;
$icy-blue: #f7fbff;
$light-yellow: #f5ffe5;
$pale-green: #e6f7cb;
$green-crayola: #27ae60;
$pale-white: #f0f0f0;
$mint-green: #f4fef5;
$blue-gray: #d1d5db;
$deep-red: #bc2f32;
$silver: #d9d9d9;
$pale-mint-green: #e3ffe4;
$tea-green: #c1fac3;
$off-white: #f7f8ff;
$minty-cream: #f5ffed;
$baby-power: #fcfff8;
$seaform-green: #baedbd;
$jordy-blue: #95a4fc;
$baby-blue: #eaf6f9;
$light-greenish-white: #f1fff5;
$yellow-orange: #ffad31;
$lust: #e01818;
$vivid-cerulean: #009cf4;
$dark-jungle-green: #101828;
$baby-blue-eye: #a2c7ff;
$space-cadet: #1c274c;
$dark-spring-green: #1a823c;
$bubbles: #e4f4ff;
$light-bubbles: #e6f5ff;
$american-silver: #d0d0d0;
$light-sky-blue: #f1f9ff;
$very-light-malachite-green: #66e08e;
$milk: #f9fff4;
$azure: #effef9;
$water: #d8fef1;
$jet-stream: #afdccd;
$floral-white: #fffaf2;
$cosmic-latte: #fff8eb;
$columbia-blue: #cbd5e1;
$diamond: #bbe7ff;
$argent: #c1c1c1;
$light-grayish-white: #f9f9f9;
$mint-cream: #f5fffd;
$light-pastel-blue-gray: #eefaf7;
$pastel-turquoise: #c2f1e6;
$pastel-sky-blue: #bfe5ff;
$kenyan-copper: #771505;
$medium-carmine: #bc4c2e;
$antique-white: #ffe9d9;
$chinese-orange: #fa703f;
$beau-blue: #b8d5ea;
$light-blue-green: #92E0CD;
$pale-yellow: #FFFEF7;
$greenish-white: #F3FFF7;
$pale-baby-blue: #DEF2FF;
$azure-blue: #0F91D2;
$light-malachite-green: #6CD592;
$pale-purple: #FAFFF7;
$cultured: #F7F7F7;
$pure-white: #FBFBFF;
$soft-green: #F8FFF4;
$pale-cream: #FFFDF8;
$off-white-color: #FFFAEE;
$lavender: #EAEBFF;
$pale-lavender: #D9DBFF;
$lavender-blue: #C7C9F7;
$polished-pine: #52AF9A;
$pale-turquoise: #ECFFFB;
$pale-blueish: #ECF2FA;
$pale-buttery-yellow: #FFFFF5;
$light-beige: #F5F5F3;
$frosty-cyan: #FBFFFE;
$light-cyan: #DEF9F3;
$pale-mint: #E8FFFA;
$grayish-white: #FAFAFA;
$azure-mist: #F7F9FF;
$beige: #FFF3EB;
$silver-gray: #E1E1E1;
$seashell: #FFF3EB;
$ocean-blue: #0C77BD;
$very-light-alice-blue: #F0F9FF;
$ivory: #FFFDF5;
$faint-pink: #FFF7F7;
$very-light-alice-blue: #f0f9ff;
$very-light-purple: #F4EFFA;
$onyx: #38343D;
$old-lavender: #797085;
$light-green: #ECFFDD;
$light-mint-green: #F7FFF1;
$light-blue-shade: #abc5f1;
$caribbean-green: #00c481;
$pale-jade: #DEFFDD;
$pale-lime: #BDFFBB;
$vivid-lime: #9BFF99;
$blonde: #FBF9C1;
$sun-lit-yellow: #F9F6A1;
$sunflower: #F6F171;
$canary-yellow: #F4EE51;
$bisque: #FFDACF;
$peachy-pink: #FFC0AD;
$apricoat: #FFA68B;
$lemon-chiffon: #FBF9D5;
$celadon: #D5FBD9;
$very-light-gray: #FBFBFB;
$aero-blue: #c7eee5;
$shade-light-blue: #D5E4F0;
$steel-gray: #757575;
$very-dark-slate-blue :#23262F;
$pastel-shade-blue: #CEE3F3;
$mustard: #FCD34D;
$begonia: #F87171;
$pale-cyan: #EFF9FF;
$mango-tango: #FFAD31;
$pastel-blue: #E2F1FC;
$light-cream: #FCEDE2;
$pale-aqua: #F0FFFB;
$powder-blue: #ECF7FF;
$light-red: #FFC0CB;
$sunset-blaze: #FF7518;
$light-salmon: #FFE4B2;
$pale-orange: #FDF3E6;
$vivid-orange: #FF6B07;
$white-smoke: #f5f4f4;
$icon-black: #292D32;
$soft-pink: #FEE2E2;
$red-orange: #FF6551;
$mint-ice: #D0F6ED;
$cool-gray: #dee2e6;
$light-sky: #E5F5FF;
$alabaster: #F8F8F8;
$ice-blue: #F1F8FE;
$almost-black: #010101;
$light-powder-blue: #F1F6FF;
// Color export
:export {
    primary: $primary-color;
    white: $white;
    gray: $gray;
    black: $black;
    pale_blue: $pale-blue;
    deep_blue: $deep-blue;
    blue_sapphire: $blue-sapphire;
    light_black: $light-black;
    charleston_green: $charleston-green;
    gainsboro: $gainsboro;
    outer_space: $outer-space;
    jet_black: $jett-black;
    space_cadet: $space-cadet;
    greenish_yellow: $greenish-yellow;
    anti_flash_white: $anti-flash-white;
    baby_power: $baby-power;
    water: $water;
    jet_stream: $jet-stream;
    bright_gray: $bright-gray;
    milk: $milk;
    pearl_aqua: $pearl-aqua;
    light_gray: $light-gray;
    beau_blue: $beau-blue;
    platinum: $platinum;
    light_blue_green: $light-blue-green;
    columbia_blue: $columbia-blue;
    red: $red;
    argent: $argent;
    eerie_black: $eerie-black;
    baby_blue: $baby-blue;
    pale_buttery_yellow: $pale-buttery-yellow;
    light_beige: $light-beige;
    silver_gray: $silver-gray;
    light_cyan: $light-cyan;
    old_lavender: $old-lavender;
    light_greenish_white: $light-greenish-white;
    light_green: $light-green;
    light_mint_green: $light-mint-green;
    pale_jade: $pale-jade;
    pale_lime: $pale-lime;
    vivid_lime: $vivid-lime;
    blonde: $blonde;
    sun_lit_yellow: $sun-lit-yellow;
    sunflower: $sunflower;
    canary_yellow: $canary-yellow;
    bisque: $bisque;
    peachy_pink: $peachy-pink;
    apricoat: $apricoat;
    light_sky_blue: $light-sky-blue;
    pastel_shade_blue: $pastel-shade-blue;
    mustard: $mustard;
    green_crayola: $green-crayola;
    begonia: $begonia;
    pastel_blue: $pastel-blue;
    light_cream: $light-cream;
    dark_silver: $dark-silver;
}