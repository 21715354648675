.e_announcement_modal {
    .e_dialog {
        max-width: 42rem;
    }
    .e_content {
        border-radius: 1.5rem;
    }
    .e_close {
        top: 32px;
        right: 32px
    }
}