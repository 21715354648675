@import "../global.scss";

.e_delete_cart {
    .e_dialog {
        max-width: 35.375rem;
    }

    .e_content {
        border-radius: 2rem;
        // height: 95vh;
    }
}

@media (max-width: 575px) {
    .e_delete_cart {
        .e_dialog {
            max-width: 100%;
        }
    }
}