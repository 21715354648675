@import "./global.scss";
@import "./Component/data-table.module.scss";

body {
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased !important;
    -webkit-appearance: none !important;
    -webkit-text-size-adjust: none !important;
    text-size-adjust: none !important;
    text-rendering: optimizeLegibility !important;
    // &::-webkit-scrollbar {
    //     display: none;
    // }

    &::-webkit-scrollbar {
        width: 0.375rem;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0.25rem;
        background-color: $primary-color;
    }
}

a {
    text-decoration: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input[type="search"]::-webkit-search-cancel-button {
    display: none;
}

.e-pointer-events-none {
    pointer-events: none;
}


.e-page-container {
    padding-top: 5.7rem;
    margin-left: 1.75rem;
    margin-right: 3.75rem;
    min-height: 100vh;
}


.e-header-advanced-search-dropdown {
    transition: max-height 0.3s ease-in-out;
    /* Transition only max-height property */
    width: 100%;
    max-height: 0;
    /* Initially hidden */
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 0.125rem;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $primary-color !important;
        border-radius: 0.25rem;
    }

    /* Hide overflow */
    &.show {
        max-height: 12rem;
        /* Show dropdown */
    }
}


.e-footer {
    padding: 1rem 5.25rem;
}

.e-quick-invest {
    .e-trending {
        top: 1.5rem;
        left: 0;
    }

    .e-quick-invest-card {
        cursor: pointer;

        &:hover {
            border-radius: 1.5rem;
            border: 0.0625rem solid $anti-flash-white;
            box-shadow: 0 0.75rem 0.75rem 0 rgba(50, 50, 71, 0.08);
        }
    }
}

.e-fund-card {
    .e-card-image {
        height: 1.875rem;
    }

    .e-type {
        padding: 0.25rem 0.375rem;
    }

    &:hover {
        box-shadow: 0 0.5rem 2rem 0 rgba(50, 50, 71, 0.08), 0 0.3125rem 1rem 0 rgba(50, 50, 71, 0.08);

        .e-fund-title {
            color: $primary-color;
        }
    }
}

.e-sip-bg {
    right: 0;
}

.e-progress-status {
    width: 20.063rem;
    height: 1.5rem;

    .e-invested-hover,
    .e-return-hover {
        border-radius: 0.375rem;
        background: $white;
        box-shadow: 0.25rem 0.25rem 0.75rem 0 rgba(175, 175, 175, 0.58);
        padding: 0;
        opacity: 1;
        z-index: 1;
    }
}

// dashboard page
.e-dashboard, .e-dashboard-sm-0 {
    margin-left: 1.75rem;
    margin-right: 3.75rem;

    .e-dashboard-banner {
        right: 0;
        border-top-right-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
    }

    .e-banner-bottom {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }

    .e-life-goals {
        border-top-left-radius: 1.875rem;
        border-bottom-left-radius: 1.875rem;
        left: 0;
    }

    .e-accound-slider {
        .slick-dots {
            li {
                margin: 0 -0.125rem;

                button:before {
                    font-size: 0.5625rem;
                }
            }
        }
    }

    .e-2px {
        margin-right: 0.125rem;

        img {
            margin-bottom: 0.125rem;
        }
    }
}

// dashboard quick invest and MFC
.e-featured-invest {
    margin-left: 1.75rem;
    margin-right: 3.75rem;
}

.e-heading-icons {
    background: rgba(224, 224, 224, 0.15);
    transition: box-shadow 0.3s;

    &:hover {
        box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }
}

.e-badge {
    top: -0.375rem;
    right: -0.375rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 7px;
}

.e-fund-mobile {
    .e-width-max-content {
        width: max-content;

        .e-fund-card {
            width: 16.5rem;
        }
    }
}

.e-news-mobile {
    width: 14rem;

    .e-news-img-mobile {
        margin-bottom: 0 !important;
    }
}

.e-fund-detail-graph {
    height: auto;
    .e-chart-container {
        width: 100%;
        canvas {
            width: 100% !important;
            height: 100% !important;
        }
    }
}

.e-investment-search {
    outline: none;
    padding-right: 2.75rem !important;
}

.e-investment-search-button {
    border-radius: 0 8px 8px 0;
}

.e-investment-search-icon {
    right: 0.75rem;
    top: 25%;
}

.e-transition-right-search {
    // transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
    // transition: all 0.3s cubic-bezier(0,.78,.19,.99);
    transition: 0.3s;

    &:hover {
        // transform: translateX(5px);
        transform: translate(5px, 0px);
    }
}

.e-drop-down {
    width: 44.375rem;
    height: 30.5rem;
    z-index: 99;
    top: 3.625rem;
    user-select: none;

    /* Shadow/lg */
    box-shadow:
        0 0.25rem 0.375rem -0.125rem rgba(16, 24, 40, 0.03),
        0 0.75rem 1rem -0.25rem rgba(16, 24, 40, 0.08);

    .e-top-searches-scroll {
        &::-webkit-scrollbar {
            width: 0.125rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary-color !important;
            border-radius: 0.25rem;
        }
    }

    .e-top-searches {
        &:hover {
            border-radius: 0.1875rem;
            background: $alice-blue;
            color: $primary-color;
        }
    }

    .e-amc-img {
        img {
            width: 3.5rem;
            // height: 2.;
        }
    }

    .e-rotate-left-90 {
        transform: rotate(-90deg);
        transition: transform 0.3s ease;
    }

    .e-rotate-right-90 {
        transform: rotate(90deg);
        transition: transform 0.3s ease;
    }

    .e-advanced-type {
        padding: 0.25rem 0.375rem;
        white-space: nowrap;
    }
}

.e-doughnut-chart-container {
    top: -0.9375rem;
    left: 0;
}

.e-doughnut-canvas {
    width: 100% !important;
    height: 17.5rem !important;
    /* 280px / 16 = 17.5rem */
}

.e-doughnut-canvas-dashboard {
    width: 100% !important;
    height: 13.5rem !important;
}

.e-doughnut-canvas-dashboard {
    width: 100% !important;
    height: 13.5rem !important;
}

.e-doughnut-legend {
    bottom: -1.875rem;
    left: -0.625rem;
}

.e-donut-sip-percentage {
    top: 30%;
    left: 0.9375rem;
}

.e-donut-lumpsum-percentage {
    bottom: 30%;
    right: 1.25rem;
}

.bar-legend-item {
    position: relative;

    &:hover {
        &::after {
            display: block;
        }
    }

    &::after {
        content: attr(data-content);
        background-color: $white;
        border-radius: 6px;
        box-shadow: 4px 4px 12px 0px rgba(175, 175, 175, 0.58);
        pointer-events: none;
        position: absolute;
        padding: 5px 10px;
        top: -35px;
        width: max-content;
        display: none;
        color: $black;
        font-family: 'Inter-Medium';
        font-size: 14px;
    }
}

.e-search-separator {
    width: 0.0625rem;
    top: 1.25rem;
    bottom: 0;
    left: 1.875rem;
    right: 0;
    margin: auto;
}

// investment header
.e-header {
    width: 80vw;
    z-index: 9;

    .e-filter {
        width: 47.375rem;
        top: 3rem;
        box-shadow: -0.25rem 0.3125rem 1rem 0 rgba(0, 0, 0, 0.1);
    }

    .e-profile-more {
        width: 23.5rem;
        box-shadow:
            0px 0px 0px 0px rgba(30, 30, 30, 0.1),
            0px 3px 7px 0px rgba(30, 30, 30, 0.1),
            0px 13px 13px 0px rgba(30, 30, 30, 0.09),
            0px 29px 18px 0px rgba(30, 30, 30, 0.05),
            0px 52px 21px 0px rgba(30, 30, 30, 0.01),
            0px 82px 23px 0px rgba(30, 30, 30, 0);
    }
}

// invest in goals
.e-invest-goals {
    .e-banner {
        right: 2rem;
    }

    .e-goals-animation {
        min-height: 6.125rem;
        /* 220px / 16 = 13.75rem */
        transition: all 0.3s ease-in-out;
        overflow: hidden;
    }

    #goal-check:checked~.e-goals-animation {
        min-height: 6.125rem;
        /* 342px / 16 = 21.375rem */
    }  
}

.e-goal-banner-image {
    z-index: 0;
    position: absolute;
}

// view pending card
.e-view-pending {
    transition: transform 0.2s;
    transform: rotate(90deg);

    &[data-view-more="true"] {
        transform: rotate(-90deg);
    }
}

// risk assessment page
.e-risk-assessment {
    .e-risk-radio-span {
        span {
            font-family: "Poppins-Regular";
            font-size: 1rem;
        }
    }

    .e-risk-range {
        max-width: 33.375rem;

        .e-range-slider {
            width: 65%;
            margin-top: 0.563rem;

            .e-labels {
                margin-top: 0.75rem;
            }
        }
    }

    .e-financial {
        width: max-content;

        .e-financial-risk {
            width: 3rem;
            height: 3rem;
        }
    }

    .e-financial-risk[data-returns=low] {
        &::before {
            position: absolute;
            content: 'Low risk - low returns';
            left: 0;
            bottom: -20px;
            font-size: 12px;
            font-family: 'Poppins-regular';
            color: $green-crayola
        }
    }

    .e-financial-risk[data-returns=high] {
        &::before {
            position: absolute;
            content: 'High risk - high returns';
            right: 0;
            bottom: -20px;
            font-size: 12px;
            font-family: 'Poppins-regular';
            color: $orange-soda;
        }
    }
}

#tooltip {
    background-color: #000;
    color: #fff;
    position: absolute;
}

// Retirement page
.e-retirement {
    .e-retirement-banner {
        background: linear-gradient(274deg, #effef9 0.82%, #059669 67.69%);

        img {
            border-radius: 0 1.25rem 1.25rem 0;
        }
    }

    .e-steps {
        padding-left: 8.75rem;
        padding-right: 8.75rem;
    }
}

.e-negative-indicator {
    width: 0;
    height: 0;
    display: inline-block;
    border-left: 0.3125rem solid transparent;
    /* 0.5rem converted to rem */
    border-right: 0.3125rem solid transparent;
    /* 0.5rem converted to rem */
    border-bottom: 0.3125rem solid red;
    /* 0.5rem converted to rem */
    margin: 0.625rem 0.3125rem;
    /* 0.625rem and 5px converted to rem */
    transform: rotate(180deg);
    vertical-align: middle;
}

.icon-link-hover {
    &:hover {
        opacity: 0.8;

        svg {
            transform: translateX(-0.3125rem);
            /* -5px / 16 = -0.3125rem */
        }
    }

    svg {
        transition: ease-in 0.3s;
    }
}

// Goal summary
.e-goal-banner {
    padding: 2.75rem 1.5rem 1.875rem 1.5rem;
    /* 44px / 16 = 2.75rem, 24px / 16 = 1.5rem, 30px / 16 = 1.875rem */
}

// SIP date picker
.e-sip-date-picker-panel {
    width: 22.4375rem;
    height: max-content;
    /* 375px / 16 = 23.4375rem */
    // height: 18.75rem;
    /* 300px / 16 = 18.75rem */
    padding: 1.25rem 0.75rem;
    left: 43.5%;
    /* 20px / 16 = 1.25rem, 12px / 16 = 0.75rem */
}

.e-sip-date-picker-date {
    transition: ease 0.3s;

    &:hover {
        font-weight: 700;
        background-color: $light-blue-shade;
    }
}

.e-disabled-date {
    background-color: $american-silver !important;
    color: $black !important;
}

// React calender
.react-calendar {
    border: none !important;
    width: 100%;

    .react-calendar__navigation__label {
        color: $black;
        font-family: "Poppins-medium";
        font-size: 1.25rem;
    }

    .react-calendar__month-view__weekdays__weekday {
        color: $black;
        font-family: "Poppins-regular";
        text-decoration: none;
        @extend %font16;
        text-transform: capitalize;
        font-weight: 500;

        abbr[title] {
            cursor: pointer !important;
            text-decoration: none !important;
        }
    }

    .react-calendar__tile--now {
        background-color: $white;
    }

    .react-calendar__tile {
        &:hover {
            background-color: $baby-blue-eye;
            font-weight: 600;
        }
    }

    .react-calendar__navigation {
        button {
            &:hover {
                background-color: $baby-blue-eye;
            }
        }
    }

    .react-calendar__navigation__label {
        pointer-events: none;
    }

    .react-calendar__tile:disabled,
    .react-calendar__navigation button:disabled {
        pointer-events: none;
        background: transparent;
        color: $american-silver;
    }

    .react-calendar__tile--active {
        background-color: $vivid-cerulean;
        color: $white;
    }
}

// Investment style pop up
.e-investment-style-popup {
    box-shadow: 0px 0.25rem 0.5rem 0px rgba(16, 24, 64, 0.08);
    width: 23.75rem;
    right: 1rem;
}

// order page
.e-order {
    .e-fund-order-hover {
        max-width: 0;
        overflow: hidden;
        transition: max-width 0.3s ease-out;
    }

    .e-fund-order-hover.show {
        max-width: 1.5rem;
        /* 24px / 16 = 1.5rem */
        /* Set the desired width */
    }

    .e-order-filter {
        width: 15.4375rem;
        /* 247px / 16 = 15.4375rem */
        box-shadow: 0 0.25rem 1.25rem -0.625rem rgba(0, 0, 0, 0.25);
        top: 3rem;
        /* 48px / 16 = 3rem */
        padding: 0.625rem;
        /* 10px / 16 = 0.625rem */
    }
}

.e-order-tab {
    font-family: "Poppins-medium" !important;
}

.e-fund-order-title {
    span {
        position: relative;
        z-index: 1;

        &::after {
            content: "";
            position: absolute;
            height: 0.0625rem;
            /* 1.5px converted to rem */
            width: 0;
            border-radius: 0.25rem;
            /* 0.25rem converted to rem */
            background-color: $primary-color;
            transition: all 0.5s ease;
            bottom: -0.25rem;
            /* 0.25rem converted to rem */
            left: 0;
        }
    }

    .e-fund-order-title-icon {
        opacity: 0;
        /* Initially, set opacity to 0 */
        transform: translateX(-90%);
        /* Move the icon offscreen to the left */
        transition:
            opacity 0.3s ease,
            transform 0.3s ease;
    }

    &:hover {
        span {
            color: $primary-color;

            &::after {
                width: 100%;
            }
        }

        .e-fund-order-title-icon {
            /* Set the width of the icon to your desired size */
            opacity: 1;
            transform: translateX(0);
            /* Slide the icon in from the left */
        }
    }
}

// watchlist page

.e-watchlist {
    min-height: 100vh;

    .e-units-held {
        top: -24px;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
    }

    .e-watchlist-hover {
        position: absolute;
        top: 2rem;
        /* 10px / 16 = 0.625rem */
        right: 1rem;
        /* 12px / 16 = 0.75rem */
        overflow: hidden;
        max-width: 24;

        &::after {
            content: "";
            position: absolute;
            background-color: $white;
            top: 0;
            left: 0;
            width: 100%;
            height: 1.875rem;
            /* 30px / 16 = 1.875rem */
            transition: ease-in-out 0.3s;
        }
    }

    .e-watchlist-hover.show::after {
        /* Use double colons for pseudo-elements and remove the tilde (~) */
        left: 100%;
    }

    .e-watchlist-social-media {
        right: -2.75rem;
        /* -44px / 16 = -2.75rem */
        bottom: -2.25rem;
        /* -36px / 16 = -2.25rem */
    }
}

.e-watchlist-card {
    &:hover {
        border-radius: 0.625rem;
        background: #fff;
        box-shadow:
            0 1.5rem 2rem 0 rgba(50, 50, 71, 0.08),
            0 0.3125rem 1rem 0 rgba(50, 50, 71, 0.08);

        .e-watchlist-title {
            span {
                color: $primary-color;
            }

            .e-fund-order-title-icon {
                /* Set the width of the icon to your desired size */
                opacity: 1;
                transform: translateX(0);
                /* Slide the icon in from the left */
            }
        }
    }

    .e-watchlist-title {
        span {
            position: relative;
            z-index: 1;
        }

        .e-fund-order-title-icon {
            opacity: 0;
            /* Initially, set opacity to 0 */
            transform: translateX(-90%);
            /* Move the icon offscreen to the left */
            transition:
                opacity 0.3s ease,
                transform 0.3s ease;
        }
    }

    .e-untis {
        width: max-content;
        top: -2.375rem;
        /* -38px / 16 = -2.375rem */
        left: -4.375rem;
        /* -70px / 16 = -4.375rem */
    }

    .e-social-media {
        bottom: -1.75rem;
        /* -28px / 16 = -1.75rem */
        left: -2rem;
        /* -32px / 16 = -2rem */
    }
}

.e-fund-suggestion-table {
    .rdt_TableBody {
        margin-top: 0 !important;
    }
}

.e-horizontal-line {
    width: 88%;
    height: 0.0625rem;
    background-color: #d1d5db;
    position: absolute;
    top: 2.5rem;
    z-index: 0;
    left: 54%;
    transform: translateX(-50%);
}

.e-edit-tooltip {
    top: -1.125rem;
    /* -18px / 16 = -1.125rem */
    left: -0.375rem;
    /* -6px / 16 = -0.375rem */
    padding: 0.125rem 0.5rem;
    /* 2px / 16 = 0.125rem, 8px / 16 = 0.5rem */
    box-shadow: 0.25rem 0.25rem 0.75rem 0 rgba(175, 175, 175, 0.58);
    /* 4px / 16 = 0.25rem */
}

.e-remove-padding-top {
    padding-top: 0;
}

// fund-order-summary page
.e-fund-order-summary {
    .e-fund-chip {
        padding: 0.25rem 0.375rem;
        /* 4px / 16 = 0.25rem, 6px / 16 = 0.375rem */
    }

    .e-border-bottom {
        position: absolute;
        height: 0.0625rem;
        width: 76%;
    }
}

.e-line-progress-status {
    width: max-content;

    .e-progress-line {
        height: 0.0625rem;
        width: 82%;
        top: 0.75rem;
        left: 50%;
        transform: translateX(-50%);
    }
}

// goal-fund-summary page
.e-goal-order-summary {
    .e-border-bottom {
        position: absolute;
        height: 0.0625rem;
        width: 84%;
    }
}

.slick-prev:before,
.slick-next:before {
    display: none;
}

// notification slider card
.e-notification-slider {
    max-height: 5.125rem;
    .slick-dots li {
        width: 0.0625rem;

        button:before {
            font-size: 0.625rem;
        }
    }

    .e-notification-card {
        .e-close {
            top: 0.0625rem;
            right: 0.875rem;
        }

        .e-sip-image {
            padding: 0.1875rem;
            /* 3px / 16 = 0.1875rem */
        }
    }

    .slick-prev:before,
    .slick-next:before {
        display: none;
    }

    .slick-next {
        top: -1.25rem;
        /* -20px / 16 = -1.25rem */
        right: 0.125rem;
        /* 2px / 16 = 0.125rem */
    }

    .slick-prev {
        top: -1.5625rem;
        /* -25px / 16 = -1.5625rem */
        transform: rotate(180deg);
        left: unset;
        right: 1.875rem;
        /* 30px / 16 = 1.875rem */
    }

    .e-notification-sip-name {
        width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

    }
}

// fund detail
.e-fund-detail {
    .e-units-held {
        white-space: nowrap;
        left: -3.875rem;
        /* -62px / 16 = -3.875rem */
        top: -2.25rem;
        /* -36px / 16 = -2.25rem */
    }

    .e-bookmark-hover {
        white-space: nowrap;
        top: -2.125rem;
        /* -34px / 16 = -2.125rem */
        left: -3rem;
        /* -48px / 16 = -3rem */
    }

    .e-chip {
        width: max-content;
        padding: 0.375rem 0.625rem;
        /* 6px / 16 = 0.375rem, 10px / 16 = 0.625rem */
    }

    .e-icon-rotate {
        transform: rotate(180deg);
    }
}

.e-segment-chart {
    left: -1.875rem;
    width: 110%;
}

.e-chart-accordion {
    background-color: $nyanza !important;
    box-shadow: none !important;
}

.e-chart-accordion[data-show="true"] {
    &::after {
        transform: rotate(180deg);
    }
}

// React calendar
.react-daterange-picker {
    // width: 100% !important;
    border-radius: 0.25rem;
    background: #fff;
    font-family: "Nunito" !important;
    font-size: 1rem !important;
}

// .react-calendar {
//     font-family: 'Nunito' !important;
// }
// abbr[title] {
//     text-decoration: none !important;
// }
// .react-daterange-picker.react-daterange-picker--open.react-daterange-picker--enabled {
//     border: 1px solid #EDD6D6 !important;
// }
// .react-daterange-picker.react-daterange-picker--closed.react-daterange-picker--enabled {
//     border: 1px solid #fff !important;
// }
// .react-daterange-picker__wrapper {
//     border-radius: 0.25rem;
//     border: none!important;
// }
// .react-daterange-picker__inputGroup {
//     padding: 9px 13px !important;
// }
// .react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon,
// .react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
//     stroke: black !important;
//     opacity: 0.6;
// }
// .e-border-error .react-date-picker__wrapper {
//     border: 1px solid #FF0000 !important;
// }
// .react-calendar__tile--now:enabled:hover,
// .react-calendar__tile--now:enabled {
//     background: transparent !important;
// }
// .react-calendar__tile--active:enabled {
//     background: #FF0000 !important;
// }
// .react-daterange-picker__inputGroup__input:invalid {
//     background: transparent !important;
// }
// .react-daterange-picker__inputGroup__input.react-daterange-picker__inputGroup__day:focus,
// .react-daterange-picker__inputGroup__input .react-daterange-picker__inputGroup__month:focus,
// .react-daterange-picker__inputGroup__input.react-daterange-picker__inputGroup__year:focus {
//     border: none !important;
// }

.e-banner-dropdown-icon {
    transition: transform 0.3s ease;
    transform: rotate(90deg);
}

[data-rotate-icon="true"] {
    transition: transform 0.3s ease;
    transform: rotate(-90deg);
}

.e-banner-dropdown {
    top: 2.125rem;
    right: 0;
    padding: 0.375rem 0.625rem;
    box-shadow: 0 0.25rem 1.25rem -0.625rem rgba(0, 0, 0, 0.25);

    p {
        white-space: nowrap;
    }
}

.e-fund-social-media {
    right: -2.875rem;
    /* -46px / 16 = -2.875rem */
    bottom: -2.25rem;
    /* -36px / 16 = -2.25rem */
}

.e-holdings-table {
    .rdt_TableBody {
        margin-top: 0;
    }

    .rdt_TableCol_Sortable>div {
        text-align: start;
    }

    .rdt_TableHeadRow> :last-of-type .rdt_TableCol_Sortable>div {
        text-align: end;
    }

    .rdt_TableCell>div:first-of-type,
    .rdt_TableCell>p:first-of-type {
        text-align: start;
    }
}

.rdt_TableCol_Sortable {
    &:hover {
        opacity: 1 !important;
    }
}

.rdt_TableCol_Sortable:hover span,
.rdt_TableCol_Sortable:hover span.__rdt_custom_sort_icon__ * {
    opacity: 1 !important;
}

.e-peers-table {
    .rdt_TableBody {
        margin-top: 0;
    }
}

.e-sector-allocation {
    padding: 1.5rem 3rem 1.5rem 1.5rem;
    /* 24px / 16 = 1.5rem, 48px / 16 = 3rem, 24px / 16 = 1.5rem, 24px / 16 = 1.5rem */
}

.e-cart {
    .e-cart-delete-hover {
        top: -2.125rem;
        /* -34px / 16 = -2.125rem */
        box-shadow: 0.25rem 0.25rem 0.75rem 0 rgba(175, 175, 175, 0.58);
        /* 4px / 16 = 0.25rem */
        white-space: nowrap;
        left: -2.625rem;
        /* -42px / 16 = -2.625rem */
    }
}

// explore filter
.e-explore-filter-popup {
    top: 3rem;
    /* 48px / 16 = 3rem */
    box-shadow: -0.25rem 0.3125rem 1rem 0 rgba(0, 0, 0, 0.1);
    /* -4px / 16 = -0.25rem, 5px / 16 = 0.3125rem, 16px / 16 = 1rem */
    width: 47.313rem;

    .e-investment-amount {
        div {
            margin-top: 0 !important;

            svg {
                margin-right: 0.5rem !important;
            }

            span {
                font-size: 0.875rem;
                line-height: 1.5rem;
                color: $black;
            }
        }
    }

    .e-close {
        top: 0.5rem;
        right: 1rem;
    }

    .e-top-amc {
        height: 12rem;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .e-theme {
        height: 9.625rem;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .e-filter-by {
        div {
            span {
                font-family: "Poppins-regular";
            }
        }

        height: 9.25rem;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .e-theme,
    .e-top-amc,
    .e-fund-category,
    .e-filter-by {
        &::-webkit-scrollbar {
            width: 0.125rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary-color !important;
            border-radius: 0.25rem;
        }
    }

    .e-fund-category {
        height: 10.625rem;
        overflow-x: hidden;
        overflow-y: scroll;
    }
}

// date filter
.e-filter-date-picker {
    top: 3.125rem;
    right: 0;
    z-index: 2;
    box-shadow: 0 1rem 2rem 0 rgba(23, 37, 76, 0.12);

    .rdrDefinedRangesWrapper {
        padding: 1rem 1.875rem 1rem 0.75rem;
        /* 16px = 1rem */
        font-size: 0.875rem;
        /* 14px / 16 = 0.875rem */
        width: 9.8125rem;
        /* 157px / 16 = 9.8125rem */
        border-right: 0.0625rem solid $bright-gray;
        /* 1px / 16 = 0.0625rem */
        border-top-left-radius: 0.5rem;
        /* 8px / 16 = 0.5rem */
        border-bottom-left-radius: 0.5rem;
        /* 8px / 16 = 0.5rem */

        .rdrStaticRange {
            border-bottom: none;
            color: $outer-space;
            font-family: "Poppins-regular";

            .rdrStaticRangeLabel {
                line-height: 1.25rem;
                /* 20px / 16 = 1.25rem */
                padding: 0.5rem 0 0.5rem 0.5rem;
                /* 8px / 16 = 0.5rem */
            }

            &:hover {
                .rdrStaticRangeLabel {
                    background-color: $light-sky-blue;
                }
            }
        }

        .rdrStaticRangeSelected {
            color: $primary-color !important;
            background-color: $bubbles;
            font-weight: 400;
        }
    }

    .rdrDateRangeWrapper {
        border-top-right-radius: 0.5rem;
        /* 8px / 16 = 0.5rem */
        border-bottom-right-radius: 0.5rem;
        /* 8px / 16 = 0.5rem */
        position: relative;

        .rdrMonths {
            margin-top: 1.875rem;

            .rdrMonth {
                padding-left: 1rem;
                padding-right: 1rem;
                width: 16.75rem;

                .rdrMonthName {
                    color: $black;
                    font-family: "Poppins-SemiBold";
                    font-size: 0.875rem;
                    padding: 0rem 0.4em;
                    text-align: center;
                    margin-bottom: 0.5rem;
                }

                .rdrWeekDays {
                    .rdrWeekDay {
                        color: $black;
                        font-family: "Inter-regular";
                        font-size: 0.875rem;
                    }
                }

                .rdrDays {
                    .rdrDay {
                        color: $black !important;
                        font-family: "Poppins-regular";
                        font-size: 0.75rem;
                        /* 12px / 16 = 0.75rem */
                        line-height: 0.75rem;
                        /* 12px / 16 = 0.75rem */

                        .rdrStartEdge,
                        .rdrEndEdge {
                            background-color: $vivid-cerulean;
                            border-radius: 0;
                        }

                        .rdrInRange {
                            background-color: $diamond;
                            border-radius: 0;
                        }

                        .rdrDayStartPreview,
                        .rdrDayEndPreview {
                            background-color: transparent;
                        }

                        .rdrDayStartPreview,
                        .rdrDayEndPreview,
                        .rdrDayInPreview {
                            border: none;
                        }
                    }

                    .rdrDayToday {
                        border: 0.0625rem solid $gray;

                        .rdrDayNumber span {
                            color: $gray;
                        }
                    }

                    .rdrDayToday:has(.rdrEndEdge),
                    .rdrDayToday:has(.rdrStartEdge) {
                        border: none;
                        color: $white;
                        background-color: $vivid-cerulean;
                    }

                    .rdrDayToday .rdrDayNumber span:after {
                        background: transparent;
                    }

                    .rdrDay:has(.rdrStartEdge),
                    .rdrDay:has(.rdrEndEdge) {
                        background-color: $vivid-cerulean;
                    }

                    .rdrDay:has(.rdrInRange) {
                        background-color: $diamond;
                    }

                    .rdrDayPassive {
                        pointer-events: unset;
                    }

                    .rdrDayPassive span {
                        color: $black;
                    }

                    .rdrDayPassive:has(.rdrEndEdge) {
                        .rdrDayNumber span {
                            color: $white;
                        }
                    }

                    // .rdrDayPassive {
                    //     .rdrDayNumber span {
                    //         color: $white;
                    //     }
                    // }

                    .rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span // .rdrDay:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span

                        {
                        color: $black;
                    }

                    .rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span,
                    .rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span {
                        color: $white;
                    }
                }
            }
        }

        .e-custom-date-navigator {
            top: 1.875rem;

            .e-left-icon {
                position: absolute;
                left: 2.75rem;
                background-color: transparent;
                border: none;
                padding: 0;
                top: 0;
            }

            .e-right-icon {
                position: absolute;
                right: 3.375rem;
                background-color: transparent;
                border: none;
                padding: 0;
                top: 0;
            }
        }
    }
}

// navigation side bar scroll style
.e-navigation-hide>div:first-of-type {
    &::-webkit-scrollbar {
        width: 0.125rem;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $primary-color !important;
        border-radius: 0.25rem;
    }
}

//manage sip page
.e-manage-sip {
    .e-sip-hover {
        max-width: 0;
        overflow: hidden;
        transition: max-width 0.3s ease-out;
    }

    .e-sip-hover.show {
        max-width: 1.5rem;
        /* Set the desired width */
    }
}

// notification page
.e-notification {
    .e-notification-toggle {
        width: 2.375rem;
        /* 38px / 16 = 2.375rem */
        height: 0.875rem;
        /* 14px / 16 = 0.875rem */

        div {
            width: 1.25rem;
            /* 20px / 16 = 1.25rem */
            height: 1.25rem;
            /* 20px / 16 = 1.25rem */
        }
    }
}

.e-fund-suggestion .rdt_TableCol:nth-child(2) {
    div {
        div {
            text-align: end;
        }
    }
}

// explore page
.e-explore {
    .e-fund-order-hover {
        max-width: 0;
        overflow: hidden;
        transition: max-width 0.3s ease-out;
    }

    .e-fund-order-hover.show {
        max-width: 1.5rem;
        /* Set the desired width */
    }

    .e-search-chip {
        box-shadow: 0 0.75rem 0.75rem 0 rgba(50, 50, 71, 0.08);

        p {
            max-width: 19.25rem;
            text-overflow: ellipsis;
        }

    }

    .e-rank-hover {
        // width: 15.25rem;
        top: 1.75rem;
        left: -3rem;
    }
}

.e-share-icon {
    width: 1.875rem;

    svg {
        fill: $black;
        cursor: pointer;

        &:hover {
            fill: $light-blue-green;
        }
    }
}

.e-filter-wrapper {
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

// faq page
.e-faq {
    .e-accordian-item {

        &:has(.show),
        &:has(.collapsing) {
            .e-heading {
                color: $primary-color;
            }
        }
    }

    .e-faq-accordian {
        .e-heading {
            color: $black;
            box-shadow: none;
            background-color: transparent;

            &::after {
                display: none;
            }
        }
    }
}

// animated select box
.e-custom-select-box {
    .e-label {
        bottom: 0;
        left: 1rem;
    }

    .e-transition-center {
        top: 0;
    }

    .e-transition-top {
        top: -0.7rem;
        left: 15px;
        padding-left: 0.375rem;
        padding-right: 0.375rem;
        margin: 0;
        transition: top 0.5s ease;
    }

    .e-error {
        bottom: -1.3rem;
    }
}

// date of birth picker
.e-date-label {
    padding: 0.85rem 3.125rem 0.85rem 0.9375rem;
    box-shadow: 0px 0.375rem 0.25rem 0px rgba(50, 50, 71, 0.03);

    .e-error {
        bottom: -1.3rem;
    }
}

.e-date-of-birth {
    top: 54px;

    .react-calendar {
        border: none;
        border-radius: 8px;
        width: 100%;

        .react-calendar__navigation__label {
            color: $black;
            font-family: "Poppins-medium";
            font-size: 1.125rem;
        }

        .react-calendar__month-view__weekdays__weekday {
            color: $black;
            font-family: "Poppins-regular";
            text-decoration: none;
            @extend %font16;
            text-transform: capitalize;
            font-weight: 500;
            font-size: 0.875rem;

            abbr[title] {
                cursor: pointer !important;
                text-decoration: none !important;
            }
        }

        .react-calendar__tile--now {
            background-color: $white;
        }

        .react-calendar__tile {
            font-size: 0.875rem;

            &:hover {
                background-color: $baby-blue-eye;
                font-weight: 600;
            }
        }

        .react-calendar__navigation {
            button {
                &:hover {
                    background-color: $baby-blue-eye;
                }
            }
        }

        .react-calendar__navigation__label {
            pointer-events: unset;
        }

        .react-calendar__tile:disabled,
        .react-calendar__navigation button:disabled {
            pointer-events: none;
            background: transparent;
            color: $american-silver;
        }

        .react-calendar__tile--active {
            background-color: $vivid-cerulean;
            color: $white;
        }
    }
}

// kyc page
.e-kyc {
    .e-progress {
        border-left: 0.125rem solid $blue-gray;
        /* 2px / 16 = 0.125rem */

        div>p:not(:first-child) {
            left: -2.75rem;
            /* -44px / 16 = -2.75rem */
            top: -0.25rem;
            /* -4px / 16 = -0.25rem */
        }

        >div:not(:last-child) {
            padding-bottom: 3.375rem;
            /* 54px / 16 = 3.375rem */
        }
    }

    .e-select-file {
        padding-top: 5px !important;
        padding-bottom: 6px !important;
    }

    // kyc summary page
    .e-summary {
        .e-progress {
            left: 50%;
            transform: translateX(-48%);
            height: 2px;
            width: 86%;
            top: 14px;
        }

        .e-summary-checkbox {
            span {
                font-family: "Poppins-Medium";
            }
        }
    }

    .e-show-info {
        box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.06);
        left: 0;
        transform: translateX(-42%);
        top: 2.5rem;
        width: 500px;
        right: 50px;
        white-space: inherit;
    }

    .e-dashed-otp {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%231177BD' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 8px;
    }

    .e-allocation-error {
        top: -2.25rem;
    }

    .e-preview-edit {
        padding-top: 2px;
        padding-bottom: 2px;
        top: -1.375rem;
        right: -0.5rem;
        box-shadow: 4px 4px 12px 0px rgba(175, 175, 175, 0.58);
    }
}

.e-kyc-user-type-card {
    &[data-coming-soon="true"] {
        &::after {
            content: "";
            position: absolute;
            top: -5px;
            right: 29%;
            background-color: $primary-color;
            width: 10px;
            height: 10px;
            z-index: -1;
        }

        &::before {
            content: "";
            position: absolute;
            top: 63px;
            right: -4.5px;
            background-color: $primary-color;
            width: 10px;
            height: 10px;
            z-index: -1;
        }
    }
}

.e-kyc-type-card-ribbon {
    padding: 2px 0;
    width: 115px;
    transform: rotate(45deg);
    top: 18px;
    right: -31px;
}

// profile page
.e-profile {
    .e-edit {
        padding-top: 2px;
        padding-bottom: 2px;
        top: -1.25rem;
        right: -6px;
        box-shadow: 4px 4px 12px 0px rgba(175, 175, 175, 0.58);
    }

    .e-bank-image {
        width: fit-content !important;
    }

    .e-bank-options {
        top: 1.75rem;
        right: 0;
        box-shadow: 0px 4px 20px -10px rgba(0, 0, 0, 0.25);
    }

    .e-bank-edit {
        top: -2.125rem;
        left: -1.625rem;
        box-shadow: 4px 4px 12px 0px rgba(175, 175, 175, 0.58);
    }

    .e-demat-edit {
        right: -1rem;
        top: -0.625rem;

        .e-edit {
            padding-top: 2px;
            padding-bottom: 2px;
            top: -0.875rem;
            right: -0.5rem;
            box-shadow: 4px 4px 12px 0px rgba(175, 175, 175, 0.58);
        }
    }

    .e-family-info {
        width: 8.438rem;
    }
}

.e-kyc-progress-track {
    height: 4px;
    width: 88px;

    .e-kyc-progress-bar {
        height: 4px;
        width: 0;
        transition: width ease-in-out 1s;

        &[data-completed-step="true"] {
            width: 100%;
        }

        &[data-completing-step="true"] {
            animation: increase-progress 0.5s forwards;
        }

        &[data-prev-step="true"] {
            animation: decrease-progress 0.5s forwards;
        }
    }
}

.e-kyc-landing-info {
    width: 13.25rem;
    box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.06);
    transform: translate(-40%, -150%);
    z-index: 10;
}

@keyframes increase-progress {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

@keyframes decrease-progress {
    0% {
        width: 100%;
    }

    100% {
        width: 0;
    }
}

.e-edit-button {
    font-size: 1rem !important;
    color: $primary-color !important;

    &:hover {
        &::after {
            display: block;
        }

        svg {
            transform: none !important;
        }
    }

    span::after {
        display: none !important;
    }

    &::after {
        content: "Edit";
        position: absolute;
        background-color: $white;
        border-radius: 0.25rem;
        /* 4px / 16 = 0.25rem */
        box-shadow: 0.25rem 0.25rem 0.75rem 0 rgba(175, 175, 175, 0.58);
        /* 4px / 16 = 0.25rem, 12px / 16 = 0.75rem */
        color: $outer-space;
        font-size: 0.75rem;
        right: 0;
        transform: translate(0.625rem, -3.75rem);
        /* 10px / 16 = 0.625rem, -60px / 16 = -3.75rem */
        padding: 0 0.375rem;
        /* 6px / 16 = 0.375rem */
        display: none;
    }
}

.e-kyc-submit-button {
    font-size: 1rem !important;
}


// family accounts page
.e-family-accounts {
    .e-invite-family {
        top: 1rem;
        right: 1rem;
    }

    .e-family-info {
        width: 8.438rem;
    }

    .e-icon {
        top: 8px;
        right: 8px;
    }

    .e-returns {
        top: -8px;
        right: 0px;
    }

    .e-investment-breakdown {

        &:has(.show),
        &:has(.collapsing) {
            margin-bottom: 16px;

            .e-heading {
                color: $primary-color;
            }
        }

        .e-heading {
            color: $gray;
            box-shadow: none;
            background-color: transparent;

            &::after {
                display: none;
            }
        }

        .e-rotate-left-90 {
            transform: rotate(-90deg);
            transition: transform 0.3s ease;
        }

        .e-rotate-right-90 {
            transform: rotate(90deg);
            transition: transform 0.3s ease;
        }
    }
    

    .e-fund-order-hover {
        max-width: 0;
        overflow: hidden;
        transition: max-width 0.3s ease-out;
    }

    .e-fund-order-hover.show {
        max-width: 1.5rem;
        /* 24px / 16 = 1.5rem */
        /* Set the desired width */
    }
}

// portfolio page
.e-portfolio {
    .e-icon {
        top: 8px;
        right: 8px;
    }

    .e-returns {
        top: -8px;
        right: 0px;
    }

    .e-bg-image {
        border-top-left-radius: 1.5rem;
        border-bottom-left-radius: 1.5rem;
    }

    .e-fund-order-hover {
        max-width: 0;
        overflow: hidden;
        transition: max-width 0.3s ease-out;
    }

    .e-fund-order-hover.show {
        max-width: 1.5rem;
        /* 24px / 16 = 1.5rem */
        /* Set the desired width */
    }

    .e-external-fund {
        width: 13.25rem;
        right: -0.625rem;
        top: -4rem;
        box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.06);
    }

    .e-portfolio-funds {
        >div:first-of-type {
            overflow-y: auto;
            padding-bottom: 145px;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .e-transform-returns {
        transform: rotate(180deg);
    }
}

.e-portfolio-options {
    top: 1.875rem;
    right: 0;
    width: 12.5rem;
    box-shadow: 0px 4px 20px -10px rgba(0, 0, 0, 0.25);
    z-index: 10 !important;
}

.e-pending-transaction-card {
    width: 20.813rem;
}

// fund portfolio detail page
.e-fund-portfolio-detail {
    .e-fund-order-hover {
        max-width: 0;
        overflow: hidden;
        transition: max-width 0.3s ease-out;
    }

    .e-fund-order-hover.show {
        max-width: 1.5rem;
        /* 24px / 16 = 1.5rem */
        /* Set the desired width */
    }

    .e-portfolio-options {
        top: 2.5rem;
        right: 0;
        width: 12.5rem;
        box-shadow: 0px 4px 20px -10px rgba(0, 0, 0, 0.25);
    }
}

// bank account page
.e-bank-account {
    .e-bank-image {
        width: fit-content !important;
    }

    .e-bank-options {
        top: 1.125rem;
        right: 1.625rem;
        box-shadow: 0px 4px 20px -10px rgba(0, 0, 0, 0.25);
    }

    .e-bank-edit {
        top: -2.125rem;
        left: -1.625rem;
        box-shadow: 4px 4px 12px 0px rgba(175, 175, 175, 0.58);
    }

    .e-bank-data-table {
        >div:first-of-type {
            overflow: visible;
        }
    }
}

.e-select-label {
    transform: translateY(0%);
    transition: ease 0.4s;
    z-index: 3;
}

.e-select-label-placeholder {
    transform: translateY(54%);
    transition: ease 0.4s;
    position: absolute;
    left: 0.75rem;
    // top: 1rem;
    z-index: 3;
}

.e-select-label-active {
    padding: 0.375rem;
    z-index: 10;
    transform: translateY(-50%);
    transition: ease 0.4s;
    color: #222b38;
    left: 0.75rem;
    top: 0;
    z-index: 3;
}

.e-text-loader {
    transform: translateY(-14%);
    display: flex;
    pointer-events: none;
    // position: absolute;
    right: 10px;
    top: 0;

    &::after {
        content: "";
        display: block;
        // width: 25px;
        // height: 25px;
        // margin: 8px;
        width: 10px;
        height: 10px;
        margin-top: 4px;
        margin-left: 8px;
        border-radius: 50%;
        border: 1.5px solid $sea-green;
        border-color: $sea-green transparent $sea-green transparent;
        animation: ring 1.2s linear infinite;
    }
}

@keyframes ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.e-rotate-180 {
    transform: rotate(180deg);
}

.e-import-fund {
    padding: 11px 14px !important;
}

// cart-invest modal
.e-cart-invest-table {
    .rdt_TableBody {
        margin-top: 0px;
    }

    >div:first-of-type {
        overflow: visible;
    }
}

.e-goal-redeem-table {
    .rdt_TableBody {
        margin-top: 0px;
        padding-bottom: 0px;
    }
}

.e-exit-load-info {
    top: 28px;
    right: -130%;
    width: 250px;
    text-align: start;

    p {
        margin-inline-end: 0;
        margin-bottom: 0;
    }
}

.e-active-breadcrumb {
    transition: ease 0.2s;

    &:hover {
        color: $black;
    }
}

.e-add-to-basket {
    top: -36px;
    left: -77%;
}

.e-fixed-filter-section {
    position: fixed;
    width: 77vw;
    height: 9.5rem;
    top: 4%;
    z-index: 5;
    background-color: $white;
}

.e-fixed-table-header {
    position: fixed;
    width: 76vw !important;
    top: 20%;
    z-index: 6;
}

.e-chip-hover {
    box-shadow: 0 0.75rem 0.75rem 0 rgba(50, 50, 71, 0.08);
}

//responsive side bar
.left-slide-modal {
    position: fixed;
    top: 0;
    left: 0;
    /* Initially off-screen */
    width: 350px;
    /* Adjust the width as needed */
    height: 100%;
    background-color: #f3f8fb;
    /* Set your preferred background color */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transition: left 0.3s ease;
    z-index: 999;
}

.e-sidebar-modal {
    width: 100%;
    height: 100%;
    padding: 20px;
    /* Adjust the padding as needed */
    overflow-y: auto;
    /* Enable scrolling if content is too long */
}

.close-icon {
    z-index: 99999;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Adjust the transparency as needed */
    z-index: 999;
    /* Ensure the overlay is above the modal */
}

.e-modal-close-btn {
    border-radius: 100px;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.1);
    }
}

// div[disabled] {
//     pointer-events: none;
//     opacity: 0.7;
// }

.modal-backdrop {
    width: 100%;
    height: 100%;
}

body.modal-open {
    overflow: hidden !important;
}

.e-tooltip-content p {
    margin-block-end: 0 !important;
}

.e-app-zoom-level-9 {
    zoom: 0.9 !important;

    .e-sidebar {
        position: fixed !important;
        height: 100%;
        width: 15% !important;
    }

    .e-header {
        width: 89vw;
    }
}

.e-app-zoom-level-8 {
    zoom: 0.8 !important;

    .e-sidebar {
        position: fixed !important;
        height: 100%;
        width: 15% !important;
    }

    .e-header {
        width: 100vw;
    }
}

.e-app-zoom-level-7 {
    zoom: 0.6 !important;

    .e-sidebar {
        position: fixed !important;
        height: 100%;
        width: 15% !important;
    }

    .e-header {
        width: 80%;
    }

    @media (-webkit-device-pixel-ratio: 1.5) {
        .e-header {
            width: 100vw !important;
        }
    }

}

.rdt_TableRow {
    .rdt_TableCell {
        &:first-child {
            justify-content: flex-start !important;
        }
    }
}

.e-fund-table-wrapper {
    .rdt_TableCell {
        &:first-child {
            padding: 22px 0 22px 24px !important;
        }
    }
}

.modal-backdrop.show {
    width: 100%;
    height: 100%;
}

.e-profile-credential {
    position: relative;

    &:hover {
        &::after {
            display: block;
        }
    }

    &::after {
        content: attr(data-tooltip);
        position: absolute;
        top: -10px;
        right: 0;
        white-space: nowrap; // Ensure the tooltip doesn't wrap to the next line
        padding: 0px 10px;
        background-color: #fff;
        border-radius: 4px;
        display: none;
        color: $outer-space;
        font-family: "Poppins-Regular";
        box-shadow: 4px 4px 12px 0px rgba(175, 175, 175, 0.58);
        font-size: 0.75rem;
    }
}

.e-kyc-decision-banner {
    top: 0px;
    bottom: -8px;
    right: 0;
    margin: auto 0;
}

// .e-margin-left-auto {
//     box-shadow: 0 1rem 2rem 0 rgba(23, 37, 76, 0.12);
// }
.e-tap-wrapper {
    width: 100%;
    overflow: auto;
    white-space: nowrap;
}

.e-area-chart-container {
    height: 508px;
}

.e-justify-content-space-between {
    justify-content: space-between;
}

.e-footer-link ,.e-contact-link {
    &:hover {
        color: $primary-color !important;
        path {
            fill: $primary-color !important;
        }
    }
}


.e-tooltip-general {
    border-radius: 0.375rem !important;
    background: $white !important;
    box-shadow: 0.25rem 0.25rem 0.75rem 0 rgba(175, 175, 175, 0.58);
    padding: 10px !important;
    opacity: 1 !important;
    z-index: 1 !important;
    max-width: 250px;
    text-align: start;
    color: $outer-space !important;
    border: 1px solid $bright-gray !important;
    font-family: 'Poppins-Regular';
    font-size: 12px !important;
    line-height: 16px !important;

    &::after {
        display: none !important;
    }

    p {
        margin-bottom: 0;
    }
}

.e-tool-tip-container {
    cursor: pointer;
}

.e-tool-tip-container:hover::after {
    display: block;
}

.e-tool-tip-container::after {
    content: attr(data-tooltip-content);
    position: absolute;
    top: -100%;
    left: -100%;
    padding: 6px;
    box-shadow: 0px 4px 20px -10px rgba(0, 0, 0, 0.25);
    color: #222b38;
    font-size: 12px;
    font-weight: 400;
    background-color: #fff;
    display: none;
    width: -moz-max-content;
    width: max-content;
}

.e-warning-info-wrapper {
    border-radius: 8px;
    background: #f1fff5;
    padding: 8px;

    p {
        color: #209e49;
    }
}

.e-report-view-wrapper table {
    font-family: 'Poppins';
    z-index: 1 !important;

    tr {
        top: auto !important
    }
}

.e-report-view-wrapper>table:first-of-type>thead {
    /* display: contents; */
    height: 100px !important;
}


.e-mandate-status {
    text-transform: lowercase;

    &::first-letter {
        text-transform: uppercase;
    }
}

.e-view-indices {
    font-family: 'Poppins-regular' !important;
    font-size: 12px !important;

    svg {
        width: 18px;
    }
}

.css-1gfmitt-MenuList {
    &::-webkit-scrollbar {
        width: 0.175rem;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0.25rem;
        background-color: $primary-color;
    }
}

.e-pending-pay-now {
    color: $primary-color !important;
    span {
        &::after {
            background-color: $primary-color !important;
        }
    }
}

.e-order-milestone-wrapper {
    &::-webkit-scrollbar {
        height: 0.175rem;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0.25rem;
        background-color: $primary-color;
    }
}

.e-cart-table {
    overflow: auto;
}

.e-rotate-left-90 {
    transform: rotate(-90deg);
    transition: transform 0.3s ease;
}

.e-rotate-right-90 {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
}

.e-compare-table {
    width: 100%;
    border: 1px solid $bright-gray;
        td {
            padding: 1.125rem 0.625rem;
            border: 1px solid $bright-gray;
            width: 20%;
            vertical-align: baseline;
        }
        .e-row-title {
            padding: 1.125rem 1.875rem;
        }
}

.e-holdings-truncate-second-line {
    width: 179px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    max-height: 41px;
}
.table-container {
    position: relative;
}

.e-comparison-wrapper { 
    display: flex;
    flex-direction: column;
}

.e-nft-card {
    padding: 1.09375rem 0.8125rem 1.21875rem 0.8125rem;
    transform: translateX(0);
    background-color: transparent;
    transition: background-color 0.5s ease, transform 0.5s ease;
    &:hover {
        background-color: $light-sky-blue;
        svg {
            transition: transform 0.5s ease;
            transform: translateX(0.3125rem);
        }
    }
}

.react-tooltip {
    z-index: 10 !important;
}

.e-hand-shake-card {
    box-shadow: 0 0.25rem 0.5rem 0 rgba(50, 50, 71, 0.06);
    padding: 7.5rem 5rem;
    border-radius: 0.625rem;
}