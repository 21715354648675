@import "../global.scss";

.e_remove_account {
    .e_dialog {
        max-width: 30.625rem;
    }

    .e_content {
        border-radius: 2rem;
        // height: 95vh;
    }
}