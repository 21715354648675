@import "../global.scss";

.e_nominee_modal {
    &::-webkit-scrollbar {
        display: none;
    }

    .e_dialog {
        max-width: 39.75rem;
    }

    .e_content {
        box-shadow: 4px 4px 48px 0px rgba(7, 0, 124, 0.08);

        .e_delete_icon {
            box-shadow: 4px 4px 12px 0px rgba(175, 175, 175, 0.58);
            top: -2.5rem;
            left: 50%;
            transform: translateX(-50%);
        }

        ._allocation_error {
            bottom: -1.375rem;
        }

        // .e_nominee_scroll {
        //     max-height: 31.25rem;
        //     overflow-y: scroll;

        //     &::-webkit-scrollbar {
        //         display: none;
        //     }
        // }

        .e_proceed_close {
            top: 24px;
            right: 24px;
        }

        // payment screen
        .e_back {
            transform: rotate(-180deg);
            left: 24px;
            top: 24px;
        }
    }
}

@media (max-width: 575px) {
    .e_nominee_modal {
        .e_dialog {
            height: 100%
        }

        .e_content{
            height: 90%;
        }
        
        .e_nominee_scroll {
            overflow-y: auto;
            overflow-x: hidden;
            padding-right: 10px;
            &::-webkit-scrollbar {
                width: 0.175rem;
            }
        
            &::-webkit-scrollbar-thumb {
                border-radius: 0.25rem;
                background-color: $primary-color;
            }
        }
    }
}