
.e_holdings_modal { 
    .e_dialog {
        max-width: 40rem;
    }
    .e_content {
        border: none;
        border-radius: 1.5rem;
        min-height: 250px;
        overflow: hidden;
    }
    .e_close {
        top: 18px;
        right: 20px
    }
    
}