@import "../global.scss";


.e_pin {
    width: 15.875rem;
    margin: 0 auto;

    input {
        height: 3.5rem;
        border: none;
        outline: none;
        opacity: 0;
    }

    label {
        top: 1.75rem;

        div {
            width: 3.5rem;
            height: 3.5rem;
        }
    }
}