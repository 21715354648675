@import "./global.scss";

@media (-webkit-device-pixel-ratio: 1.5) {

    .e-zoom-out-wrapper,
    body {
        zoom: 0.9;
    }

    .e-header {
        width: 100vw;
    }

    .e-sidebar {
        position: fixed !important;
        height: 100%;
        width: 15% !important;
    }

    @media (max-width: 992px) {
        .e-sidebar {
            width: 100% !important;
            position: relative !important;

            &::-webkit-scrollbar {
                width: 0.375rem;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 0.25rem;
                background-color: $primary-color;
            }
        }
    }
}

@media (-webkit-device-pixel-ratio: 1.75) {

    .e-zoom-out-wrapper,
    body {
        zoom: 0.8;
    }

    .e-header {
        width: 80% !important;
    }

    .e-sidebar {
        position: fixed !important;
        height: 100%;
        width: 16% !important;
    }
}

// @media (-webkit-device-pixel-ratio: 2) {
//     .e-zoom-out-wrapper,
//     body {
//         zoom: 0.9;
//     }
//     .e-header {
//         width: 100vw;
//     }
//     .e-sidebar {
//         position: fixed !important;
//         height: 100%;
//         width: 15% !important;
//     }
// }

// XX-Large devices (larger desktops, 1400px and down)
@media (max-width: 1400px) {
    .e-quick-invest .e-quick-invest-card {
        padding: 16px 9px;
    }

    .e-quick-invest {
        .e-trending {
            top: 1.5rem;
            left: 0;
            transform: scale(0.9);
            transform-origin: left;
        }
    }
}

@media (max-width: 1350px) {
    .e-payment-other-activities {
        font-size: 0.875rem !important;
    }

    .e-quick-invest .e-quick-invest-card p {
        font-size: 15px;
    }

    .e-quick-invest {
        .e-trending p {
            font-size: 10px;
        }
    }

    .e-kyc-investment-type {
        font-size: 18px;
    }

    .e-kyc-decision-banner {
        bottom: -60px;
    }
}

@media (max-width: 1300px) {
    .e-quick-invest {
        .e-trending {
            transform: scale(0.8);
        }
    }

    .e-font-16-xl-14 {
        font-size: 0.875rem;
    }

}

// X-Large devices (large desktops, 1200px and down)
@media (max-width: 1200px) {
    .e-header {
        width: 80vw;
    }

    @media (-webkit-device-pixel-ratio: 1.5) {
        .e-header-btn {
            // padding: 14px 10px !important;
            font-size: 12px !important;
        }
    }

    .e-sidebar-menu-wrapper,
    .e-sidebar-logo {
        zoom: 0.8;
    }

    .e-quick-invest-scroll {
        &::-webkit-scrollbar {
            height: 0;
            display: initial !important;
        }

        overflow-x: scroll;

        // > div:first-of-type {
        //     width: 31rem;
        >div:first-of-type {
            width: max-content;
        }

        .e-quick-invest-card {
            // padding: 1rem 0.75rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            min-width: 120px;

            >img {
                width: 2rem;
                height: 2rem;
                margin-top: 38px;
            }

            >p {
                font-size: 12px !important;
            }
        }
    }

    .min-height-142px-sm-120 {
        min-height: 120px;
    }

    .e-quick-invest {
        .e-trending {
            transform: scale(1);

            p {
                font-size: 12px;
            }
        }
    }

    .e-kyc-user-type-card[data-coming-soon="true"]::after {
        display: none;
    }

    .example {
        display: none;
    }

    .e-table-scroll-indicator-watchlist {
        display: block;
    }

    .e-retirement {
        .e-steps {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    .e-goal-banner-image {}

    .e-sector-allocation {
        padding: 0;
    }

    .e-fund-name {
        width: 358px !important;
    }

    .e-payment-other-activities {
        font-size: 0.75rem !important;
    }

    .e-onboard-progress {
        width: 800px;
    }

    .e-margin-left-auto {
        margin-left: auto;
    }

    .min-height-335px {
        min-height: 12.9375rem;
    }

    .e-kyc-investment-type {
        font-size: 14px;
    }

    .e-pending-transaction-card {
        width: 100%;
    }


    .e-report-view-wrapper {
        overflow: auto;

        table {
            width: 1200px;
        }
    }
    .e-table-container {
        // height: 100vh;
        // overflow-y: hidden;
    }
    .e-comparison-wrapper {
        overflow-x: auto;
        // overflow-y: hidden;

        &::-webkit-scrollbar {
            height: 0.125rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary-color !important;
            border-radius: 0.25rem;
        }
    }

    .e-compare-summary-wrapper {
        width: 63.75rem;
    }

    .e-compare-table-wrapper {
        width: 65rem;
        overflow-y: auto;
        overflow-x: hidden;
        height: 55vh;
    }
    .e-holdings-truncate-second-line {
        width: inherit;
    }
}

@media (max-width: 1060px) {
    .e-header-btn {
        padding: 14px 6px !important;
        font-size: 12px !important;
    }

    .e-table-scroll-indicator-sip {
        display: block;
    }

    .e-kyc-user-type-card {
        padding: 20px 10px;
    }

}

@media (max-width: 1024px) {
    html {
        zoom: 1;
    }

    .e-fund-detail-banner-footer {
        .e-nav-column {
            font-size: 1.5rem !important;
        }
    }
}

// Large devices (desktops, 992px and down)
@media (max-width: 992px) {
    .e-nav-items {
        width: unset !important;
    }
    .e-menu-border.border-right-1px {
        border-right: 0 !important;
    }

    .margin-120px-lg-top {
        margin-top: 0;
    }

    .e-dashboard {
        margin-right: 0;
    }

    .margin-14px-md-top {
        margin-top: 0.875rem;
    }

    .border-lg-right-1px {
        border: none;
    }

    .margin-120px-lg-top {
        margin-top: 0;
    }

    .margin-14px-md-top {
        margin-top: 0.875rem;
    }

    .padding-30px-left-lg {
        padding-left: 0;
    }

    .e-navigation-hide {
        display: none;
    }

    .e-page-container {
        padding-top: 0.625rem;
    }

    .e-page-container {
        margin-right: 1.75rem;
    }

    .e-payment-other-activities {
        font-size: 0.875rem !important;
    }

    html {
        zoom: 0.9;
    }

    .e-fixed-filter-section {
        width: 94%;
        height: 8.5rem;
        top: -4%;
        z-index: 7;
        background-color: #fff;
    }

    .e-fixed-table-header {
        width: 94% !important;
        top: 13%;
        z-index: 7;
    }

    .e-risk-score-scale {
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 18px;
    }

    .e-payment-activities-wrapper {
        gap: 24px;
    }

    .e-drop-down {
        overflow-y: scroll;
        overflow-x: hidden;
        width: 31.375rem;

        &::-webkit-scrollbar {
            width: 0.125rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary-color !important;
            border-radius: 0.25rem;
        }
    }

    // .e-risk-assessment .e-financial .e-financial-risk {
    //     width: 3rem;
    //     height: 3rem;
    // }
    .e-explore-filter-popup {
        max-height: 610px;
        overflow-y: auto;
        width: 38.313rem;

        &::-webkit-scrollbar {
            width: 0.125rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary-color !important;
            border-radius: 0.25rem;
        }
    }

    .e-search-width-100 {
        width: 434px !important;
    }

    .min-height-335px {
        min-height: 20.9375rem;
    }

    .e-kyc-user-type-card {
        padding: 20px;
    }

    .e-kyc-investment-type {
        font-size: 20px;
    }

    .padding-64px-lr-md {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .e-cart-table {
        overflow: auto;

        table {
            width: 800px;
        }
    }
}

@media (max-width: 880px) {
    .e-table-scroll-indicator {
        display: block;
    }
}

// Small devices (landscape phones, 576px and down)
@media (max-width: 576px) {
    html {
        zoom: 0.8;
    }

    .e-dashboard {
        margin-left: 1.75rem;
        margin-right: 1.75rem;
    }

    .e-featured-invest {
        margin-left: 0;
        margin-right: 0;
    }

    .e-quick-invest-scroll {
        &::-webkit-scrollbar {
            height: 0;
            display: initial !important;
        }

        overflow-x: scroll;

        // > div:first-of-type {
        //     width: 31rem;
        >div:first-of-type {
            width: max-content;
        }

        .e-quick-invest-card {
            // padding: 1rem 0.75rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            min-width: 116px;

            >img {
                width: 2rem;
                height: 2rem;
                margin-top: 38px;
            }

            >p {
                font-size: 14px;
            }
        }
    }

    .e-quick-invest {
        .e-trending {
            transform: scale(1);

            p {
                font-size: 12px;
            }
        }
    }


    .e-search-width-100 {
        width: 100%;
    }

    .e-drop-down {
        // position: fixed!important;
        width: 100vw !important;
        left: -40px !important;
        // top: 90px!important;
    }

    .e-dashboard,
    .e-featured-invest {
        margin-right: 0;
    }

    .min-height-335px {
        min-height: 17.125rem;
    }

    .min-height-130px-lg {
        min-height: 0;
    }
}

// Medium devices (tablets, 768px and down)
@media (max-width: 768px) {
    .e-sip-border-none {
        border: none;
    }
    .border-right-1px-md {
        border-right: none;
    }

    .e-drop-down {
        overflow-y: scroll;
        overflow-x: hidden;
        width: 31.375rem;

        &::-webkit-scrollbar {
            width: 0.125rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary-color !important;
            border-radius: 0.25rem;
        }
    }

    .bg-very-light-gray-md {
        background-color: transparent;
    }

    .e-margin-left-auto {
        width: 100%;

        >div:first-of-type {
            width: inherit;
        }

        .e-filter-date-picker {
            width: 100%;

            >div:first-of-type {
                width: 100%;
                overflow-y: scroll;

            }
        }
    }

    .e-goal-banner-image {
        width: 100%;
        position: relative;
        height: 300px !important;
        // object-fit: cover;
    }

}

// Small devices (landscape phones, 576px and down)
@media (max-width: 576px) {
    html {
        zoom: 0.8;
    }

    .rdt_Table {
        padding-bottom: 0 !important;

    }

    .h-sm-100vh {
        height: 100%;
    }

    .gap-sm-16px-0px {
        gap: 0rem;
    }

    .border-right-sm {
        border-right: 0;
    }

    .gap-sm-16px-8px,
    .gap-sm-14px-8px,
    .gap-sm-12px-8px,
    .gap-sm-24px-8px,
    .gap-12px-sm-8 {
        gap: 0.5rem;
    }

    .gap-24px-sm-12 {
        gap: 0.75rem;
    }

    .gap-24px-sm-8 {
        gap: 0.5rem;
    }

    .gap-32px-sm-16,
    .gap-24px-sm-16,
    .gap-16-xs {
        gap: 1rem;
    }


    .gap-sm-36px-16px,
    .gap-sm-24px-16px {
        gap: 1rem;
    }

    .w-sm-190px,
    .w-sm-170,
    .w-sm-150 {
        width: auto !important;
    }

    .e-header-advanced-search-dropdown.show {
        max-height: fit-content;
    }

    .e-font-18-sm-14 {
        font-size: 0.875rem;
    }

    .e-font-18-sm-12 {
        font-size: 0.75rem;
    }

    .e-font-22-sm-18,
    .e-font-20-sm-18 {
        font-size: 1.125rem;
    }

    .e-font-14-sm-12 {
        font-size: 0.75rem;
    }

    .e-font-12-sm-10 {
        font-size: 0.625rem;
    }

    .e-font-12-sm-14 {
        font-size: 0.875rem;
    }

    .e-font-16-sm-10 {
        font-size: 0.625rem;
    }

    .e-font-16-sm-12 {
        font-size: 0.75rem;
    }

    .e-font-sm-16-14 {
        font-size: 0.875rem;
    }

    .e-font-18-sm-16 {
        font-size: 1rem;
    }

    .e-font-sm-24-20 {
        font-size: 1.125rem;
    }

    .e-font-32-sm-24 {
        font-size: 1.5rem;
    }

    .e-font-32-sm-18 {
        font-size: 1.125rem;
    }

    .e-font-24-sm-18 {
        font-size: 1.125rem !important;
    }

    .e-font-24-sm-14 {
        font-size: 0.875rem !important;
    }

    .line-height-sm-14px-18px {
        line-height: 1.125rem;
    }

    .line-height-sm-14px-20px {
        line-height: 1.25rem;
    }

    .line-height-16px-sm-24px {
        line-height: 1.5rem;
    }

    .line-height-sm-20px-24px {
        line-height: 1.5rem;
    }

    .line-height-34px-sm-24px {
        line-height: 1.5rem;
    }

    .line-height-48px-sm-32px {
        line-height: 2rem;
    }

    .line-height-22px-sm-26px {
        line-height: 1.625rem;
    }

    .margin-32px-bottom-sm-16,
    .margin-24px-bottom-sm-16 {
        margin-bottom: 1rem;
    }

    .margin-24px-top-sm-12 {
        margin-top: 0.75rem;
    }

    .margin-26px-top-sm-16 {
        margin-top: 1rem;
    }

    .margin-26px-top-sm-8 {
        margin-top: 0.5rem;
    }

    .margin-40px-bottom-sm-16 {
        margin-bottom: 1rem;
    }

    .margin-46px-bottom-sm-16 {
        margin-bottom: 1rem;
    }

    .margin-72px-bottom-sm-16 {
        margin-bottom: 1rem;
    }

    .margin-32px-top-sm-16,
    .margin-24px-top-sm-16,
    .margin-48px-top-sm-16,
    .margin-40px-top-sm-16 {
        margin-top: 1rem;
    }

    .margin-56px-top-sm-16,
    .margin-30px-top-sm-16 {
        margin-top: 1rem;
    }

    .margin-56px-top-sm-0 {
        margin-top: 0;
    }

    .margin-42px-top-sm-16,
    .margin-50px-top-sm-16 {
        margin-top: 1rem;
    }

    .padding-10px-mob-top {
        padding-top: 0.625rem;
    }

    .padding-100px-right-sm-0 {
        padding-right: 0;
    }

    .padding-24px-all-sm-px-0-py-16 {
        padding: 1rem 0;
    }

    .padding-40px-bottom-sm-24 {
        padding-bottom: 1.5rem;
    }

    .padding-28px-bottom-sm-16 {
        padding-bottom: 1rem;
    }

    .padding-64px-top-sm-16 {
        padding-top: 1rem;
    }

    .padding-94px-bottom-sm-16 {
        padding-bottom: 1rem;
    }

    .padding-32px-right-sm-16 {
        padding-right: 1rem;
    }


    .padding-40px-left-sm-16 {
        padding-left: 1rem;
    }

    .padding-56px-bottom-sm-16 {
        padding-bottom: 1rem;
    }

    .padding-32px-all-sm-16 {
        padding: 1rem;
    }

    .padding-12px-tb-sm-8 {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .border-radius-30px-sm-0 {
        border-radius: 0;
    }

    .min-width-60-sm-50 {
        min-width: 3.125rem;
    }

    .overflow-sm-hidden {
        overflow: auto;
    }

    .e-dashboard-sm-0 {
        margin-left: 0;
        margin-right: 0;
    }

    .modal-dialog {
        max-width: 100% !important;
    }

    .react-calendar {
        width: 100% !important;
    }

    .Toastify__toast-container {
        width: 100% !important;
    }

    .min-height-144px-sm-8rem {
        min-height: 4rem;
    }

    .e-dashboard {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .e-life-goal {
        overflow-x: auto;
    }

    .e-featured-invest {
        margin-left: 0;
        margin-right: 0;
    }


    .e-progress-status {
        width: 100%;
    }

    .e-sip-border-none {
        padding-right: 0;
    }

    .e-dashboard {
        .e-dashboard-banner {
            width: 100%;
        }
    }

    .min-height-255px {
        min-height: 16.062rem;
    }

    .min-height-169px {
        min-height: 10.5625rem;
    }

    .e-featured-mobile {
        padding-left: 16px;
        padding-right: 16px;
    }

    .e-life-card {
        overflow-x: scroll;
        padding-left: 1rem;
        padding-right: 1rem;

        &::-webkit-scrollbar {
            height: 0;
            display: initial !important;
        }

        .e-life-mobile {
            padding: 1rem 1.25rem;

            .e-mobile-margin {
                margin-bottom: 0.5rem;
            }
        }

        >div:first-of-type {
            width: 41.625rem;
        }
    }

    .e-fund-mobile {
        overflow-x: scroll;

        &::-webkit-scrollbar {
            height: 0;
            display: initial !important;
        }
    }

    .e-news-mobile-scroll {
        overflow-x: scroll;

        &::-webkit-scrollbar {
            height: 0;
            display: initial !important;
        }
    }

    .e-sip-mobile {
        border-radius: 0;
    }

    .e-mobile-sip {
        width: 100%;
    }

    .e-profile-bank-table-wrapper {
        .rdt_Table {
            padding-bottom: 25px !important;

        }
    }

    .e-page-container {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
    }

    .e-risk-assessment {
        .e-risk-range {
            .e-range-slider {
                width: 100%;
            }
        }
    }

    .e-sip-detail-invest-btn {
        width: 100%;
    }

    .e-drop-down,
    .e-search-width-100 {
        width: 100%;
    }

    .mob-w-100 {
        width: 100%;
    }

    .margin-40px-lg-top {
        margin-top: 0;
    }

    .margin-28px-bottom-sm-16 {
        margin-bottom: 1rem;
    }

    .margin-20px-bottom-sm-10 {
        margin-bottom: 0.625rem;
    }

    .margin-38px-top-sm-24 {
        margin-top: 1.5rem;
    }

    .min-height-335px-sm-224 {
        min-height: 224px;
    }

    .padding-40px-all {
        padding: 20px;
    }

    .mob-right-0 {
        right: 0;
    }

    .mob-w-max-content {
        width: max-content;
    }

    .padding-62px-right-mob {
        padding-right: 0;
    }

    .padding-5px-right-mob {
        padding-right: 5px;
    }

    .e-kyc-user-type-card[data-coming-soon="true"]::after {
        display: none;
    }

    .e-fund-detail-banner-footer .e-nav-column {
        font-size: 1.2rem !important;
    }

    .e-sip-date-picker-panel {
        left: 10.5%;
        top: 50%;
    }

    .e-fund-indicator-positive {
        transform: scale(0.7);
        transform-origin: center;
        margin-top: 0.5rem !important;
    }

    .e-fund-indicator-negative {
        transform: scale(0.7) rotate(180deg) !important;
        transform-origin: center;
        margin-top: 0.625rem !important;
    }

    .e-exit-load-info {
        right: 0;
        line-height: 20px;
    }

    .e-retirement .e-steps {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .e-goal-banner-image {
        height: 200px !important;
        object-fit: contain;
    }

    .e-fund-detail-graph {
        height: auto;
        min-height: 380px;
    }

    .e-chart-container {
        width: 100%;
        max-width: 100%;
        position: relative;

        canvas {
            width: 100% !important;
            height: auto !important;
        }
    }

    /*----------goal summary---*/

    .e-goal-listing-banner-img {
        width: 150px;
    }

    .e-goal-progress {
        width: 600px;
        overflow: auto;
        position: relative;

        &::-webkit-scrollbar {
            height: 0.125rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary-color !important;
            border-radius: 0.25rem;
        }
    }

    .e-goal-banner {
        padding: 1rem;
    }

    .e-horizontal-line {
        left: 55%;
        top: 28%;
    }

    .e-total-value-img,
    .e-goal-banner-img,
    .e-btn-d-none,
    .e-kyc-decision-banner {
        display: none;
    }

    .padding-32px-left-sm-8 {
        padding-left: 0.5rem;
    }

    .padding-32px-left-sm-16 {
        padding-left: 1rem;
    }

    .e-risk-analysis-wrapper {
        justify-content: center;
    }

    .padding-40px-right-mob {
        padding-right: 40px !important;
    }

    .e-invest-btn-w-100 {
        width: 100%;
    }

    .e-kyc-title {
        font-size: 1.5rem !important;
    }

    .e-p-line-height {
        line-height: 1.25rem !important;
    }

    .e-risk-assessment .e-financial .e-financial-risk {
        width: 2.5rem;
        height: 2.5rem;
    }

    .e-explore-filter-popup {
        width: 100%;
        height: 100%;
        position: fixed !important;
        top: 0;
        max-height: 100%;
        left: 0;
    }

    .e-explore-filter-mob {
        justify-content: end;
    }

    .d-mob-none {
        display: none !important;
    }

    .e-investment-style-popup {
        right: 0px;
        left: -50px;
        margin: auto;
    }

    .e-search-width-100 {
        width: 100% !important
    }

    .w-mobile-100 {
        width: 100%;
    }

    .e-cart-invest-table {
        overflow: auto;
    }

    .e-fund-detail-graph {
        overflow: auto;
    }

    .margin--10-top-mobile {
        margin-top: -0.625rem;
    }

    // .e-filter-date-picker {
    //     width: 27.5rem;
    // }
    .e-fund-table-wrapper {
        .rdt_Table {
            padding-left: 0;
            padding-right: 0;
        }

        .rdt_TableHeadRow {
            border-bottom: 0;
            padding-bottom: 6px !important;
        }

        .rdt_TableBody {
            margin-top: 0;
        }

        .rdt_TableRow {
            margin-bottom: 0;
            border: none;
            border-bottom: 1px solid #ecedef;
            border-radius: 0;
        }

        .rdt_TableCell:first-child {
            padding: 12px 0 12px 0px !important;
        }
    }

    .e-holdings-table,
    .e-peers-table {
        .sc-dmXWDj {
            &::-webkit-scrollbar {
                height: 0.275rem;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 0.25rem;
                background-color: $primary-color;
            }
        }
    }

    .e-mobile-table {
        .rdt_Table {
            padding-left: 0;
            padding-right: 0;
        }

        .rdt_TableHeadRow {
            border-bottom: 0;
            padding: 0;
            padding-bottom: 6px !important;
        }

        .rdt_TableBody {
            margin-top: 0;
        }

        .rdt_TableRow {
            margin-bottom: 0;
            border: none;
            border-bottom: 1px solid #ecedef;
            border-radius: 0;
            padding: 14px 0px;
        }

        .e-mf-name {
            width: 85%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .h-64px-sm-48 {
        height: 3rem;
    }


}

@media (max-width: 380px) {

    .w-sm-190px,
    .w-sm-170,
    .w-sm-150 {
        width: 100% !important;
    }

    .e-risk-assessment .e-financial {
        width: auto;
    }
}