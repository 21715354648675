@import "../global.scss";

.e_risk_modal {
    .e_risk_dialog {
        max-width: 30rem; /* 480px converted to rem */

        .e_risk_content {
            padding: 1.875rem 2.75rem 2.5rem 2.75rem; /* 30px, 60px, 40px, 60px converted to rem */

            .e_close {
                right: 1rem; /* 16px converted to rem */
                top: 1rem; /* 16px converted to rem */
            }

            .e_buttons {
                right: 24px;
                bottom: 24px;

                >button:first-of-type {
                    border-radius: 6px;
                }
            }
        }
    }
}
