@import "../global.scss";

.e_unlock {
    .e_dialog {
        max-width: 35rem;

        .e_content {
            border-radius: 2rem;
            border: none;
            padding: 2.125rem 3.375rem 3rem 3.375rem;
            user-select: none;

            .e_pin {
                width: 15.875rem;
                margin: 0 auto;

                input {
                    height: 3.5rem;
                    border: none;
                    outline: none;
                    opacity: 0;
                }

                label {
                    div {
                        width: 3.5rem;
                        height: 3.5rem;
                    }
                }
            }
        }
    }
}