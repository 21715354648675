@import "../global.scss";

.e_lumpsum_modal {
    .e_lumpsum_dialog {
        max-width: 39.75rem;
    }

    .e_lumpsum_content {
        box-shadow: 4px 4px 48px 0px rgba(7, 0, 124, 0.08);
    }
}

@media (max-width: 575px) {
    .e_lumpsum_modal {
        .e_lumpsum_dialog {
            max-width: 100%;
        }
    }
}