@import '../global.scss';

.e_indices_modal {
    .e_dialog {
        max-width: 25rem;
        height: 60vh;
    }

    .e_content {
        border: none;
        border-radius: 1.5rem;
        min-height: 350px;
        overflow: hidden;
    }

    .e_close {
        top: 16px;
        right: 16px
    }

    .e_table_wrapper {
        max-height: 300px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 0.125rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary-color !important;
            border-radius: 0.25rem;
        }
    }
}

@media (max-width: 575px) {
    .e_holdings_modal {
        .e_dialog {
            max-width: 100%;
        }
    }
}