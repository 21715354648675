@import "../global.scss";

.e_risk_score {
    .e_risk_dialog {
        max-width: 480px;

        .e_risk_content {
            border-radius: 1rem;
            border: none;
            padding: 2rem 1.75rem;

        }
    }
}