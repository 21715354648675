@import '../global.scss';

.e_navigation_items {
    border-left: 0.125rem solid transparent;
    min-width: 100%;
    width: max-content;
    &:hover {
        background: $bright-gray;
    }
}

.e_menu_item_wrapper {
    width: 175px;
}