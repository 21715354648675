@import '../global.scss';

.e_goal_redeem {
    &::-webkit-scrollbar {
        display: none;
    }

    .e_body {
        max-height: 70vh;
        overflow-y: scroll;
        padding-left: 10px;
        &::-webkit-scrollbar {
            width: 0.175rem;
        }
    
        &::-webkit-scrollbar-thumb {
            border-radius: 0.25rem;
            background-color: $primary-color;
        }
    }

    .e_dialog {
        max-width: 39.75rem;
    }

    .e_content {
        border: none;
    }

    .e_rotate_left_90 {
        transform: rotate(-90deg);
        transition: transform 0.3s ease;
    }

    .e_rotate_right_90 {
        transform: rotate(90deg);
        transition: transform 0.3s ease;
    }

    .e_close {
        top: 16px;
        right: 26px
    }

    .e_proceed_close {
        top: 24px;
        right: 24px;
    }

    .select_bank_radio {
        width: 17.313rem;

        @media (max-width: 576px) {
            width: 100%;
        }
    }

    // payment screen
    .e_back {
        transform: rotate(-180deg);
        left: 24px;
        top: 24px;
    }

}