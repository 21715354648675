
@import "../global.scss";

.e_compare_modal {
    .e_dialog {
        max-width: 45rem;
        // height: 90vh;
            .e_content {
                border-radius: 2rem;
                height: 85vh;
                .e_search_result {
                    &::-webkit-scrollbar {
                        width: 0.175rem;
                    }
                
                    &::-webkit-scrollbar-thumb {
                        border-radius: 0.25rem;
                        background-color: $primary-color;
                    }
                }
            }
    }
}