@import "../global.scss";

.e_import_external_fund {
    .e_dialog {
        max-width: 39.75rem;
    }

    .e_content {
        border-radius: 1.5rem;
    }

    .e_close {
        top: 2rem;
        right: 2rem;
    }

    .e_width_386 {
        width: 24.125rem;
    }
    .e_back {
        transform: rotate(180deg);
    }
}

@media (max-width: 575px) {
    .e_import_external_fund {
        .e_dialog {
            max-width: 100%;
        }
    }
}