@import '../global.scss';

.e_holdings_modal {
    .e_dialog {
        max-width: 40rem;
        // height: 80vh;
    }

    .e_content {
        border: none;
        border-radius: 1.5rem;
        max-height: 90vh;
        overflow: hidden;

        .e_modal_body {
            overflow-y: auto;
            overflow-x: hidden;
            width: 100%;
            min-height: 48.5vh;
            &::-webkit-scrollbar {
                width: 0.175rem;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 0.25rem;
                background-color: $primary-color;
            }
        }
    }

    .e_close {
        top: 16px;
        right: 18px
    }

}

@media (max-width: 575px) {
    .e_holdings_modal {
        .e_dialog {
            max-width: 100%;
            // height: 80vh;
        }
    }
}