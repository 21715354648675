@import '../global.scss';

.e_kyc_confirm_modal {
    .e_dialog {
        max-width: 52rem;
        height: 80vh;
    }

    .e_content {
        border: none;
        border-radius: 1.5rem;
        min-height: 550px;
        overflow: hidden;
    }

    .modal_body {
        max-height: 70vh;
        overflow-y: scroll;
        overflow-x: hidden;
        // padding-right: 10px;
        &::-webkit-scrollbar {
            width: 0.175rem;
        }
    
        &::-webkit-scrollbar-thumb {
            border-radius: 0.25rem;
            background-color: $primary-color;
        }
    }

    .e_close {
        top: 16px;
        right: 16px
    }

    .e_nominee_container {
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 20px;
        &::-webkit-scrollbar {
            height: 0.175rem;
        }
    
        &::-webkit-scrollbar-thumb {
            border-radius: 0.25rem;
            background-color: $primary-color;
        }
    }
    
}

