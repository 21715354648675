@import '../global.scss';

.e_see_more {
    width: max-content;
    span {
        cursor: pointer;
        position: relative;
        white-space: nowrap;
        &::after {
            content: "";
            position: absolute;
            height: 0.09375rem;
            /* 1.5px converted to rem */
            width: 0;
            border-radius: 0.25rem;
            /* 4px converted to rem */
            background-color: $sea-green;
            transition: all 0.5s ease;
            bottom: -0.25rem;
            /* 4px converted to rem */
            left: 0;
        }
    }
    &:hover {
        span {
            &::after {
                width: 100%;
            }
        }
    }

    &:hover {
        .e_icon {
            transform: translateX(5px);
            /* 0.25em converted to rem */
        }
    }

    .e_icon {
        transition: all 0.3s cubic-bezier(0, 0, .2, 1);
    }
}