
@import "../global.scss";

.update_relation {
    .e_dialog {
        max-width: 695px;
        max-height: 100vh;
    }
    .e_content {
        .e_content_wrapper {
            height: 75vh;
            overflow-x: hidden;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 0.125rem;
            }
        
            &::-webkit-scrollbar-thumb {
                background-color: $primary-color !important;
                border-radius: 0.25rem;
            }
        }
    }
}

@media (max-width: 575px) {
    .update_relation {
        .e_content {
            height: 80vh;
            .e_content_wrapper {
                height: 90vh;
            }
        }
    }
}